<template>
	<div>
		<a-modal
			title="添加自有设备"
			class="tinazj"
			:visible="true"
			@cancel="editziyou_handleCancel">
			<template slot="footer">
				<a-button key="back" @click="editziyou_handleCancel">
					取消
				</a-button>

				<a-button
					key="submit"
					type="primary"
					:loading="isCheckLoading"
					:disabled="!isCheckAvailable"
					@click="editname_handleOk">
					确定
				</a-button>
			</template>
			<div style="margin-top: 20px">
				<div
					style="line-height: 32px"
					class="timinha ant-form-item-required">
					设备名称
				</div>
				<a-input
					style="width: 440px; height: 36px"
					placeholder="请输入设备名称，便于识别"
					v-model="chec_name" />
			</div>
			<!-- <div style="margin-top: 20px">
        <div style="line-height: 32px" class="timinha ant-form-item-required">
          网络属性
        </div>
        <a-radio-group v-model="chec_attr">
          <a-radio value="staticState"> 静态</a-radio>
          <a-radio value="dynamic"> 动态</a-radio>
        </a-radio-group>
      </div> -->
			<div style="margin-top: 20px">
				<div
					style="line-height: 32px"
					class="timinha ant-form-item-required">
					代理类型
				</div>

				<a-select
					size="default"
					v-model="chec_type"
					placeholder="请选择网络类型"
					class="seclok"
					style="width: 440px; height: 36px">
					<a-select-option v-for="i in addhttplist" :key="i">
						{{ i }}
					</a-select-option>
				</a-select>
			</div>
			<div style="margin-top: 20px">
				<div
					style="line-height: 32px"
					class="timinha ant-form-item-required">
					设备地址或域名
				</div>
				<a-input
					style="width: 280px; height: 36px"
					placeholder="设备地址或域名"
					v-model.trim="chec_ip"
					@change="handleIP" />
				:
				<a-input
					style="width: 80px; height: 36px"
					placeholder="端口号"
					v-model.trim="chec_port"
					@change="handlePort" /><a-button
					style="margin-left: 5px"
					type="primary"
					:loading="isCheckLoading"
					@click="checkIp"
					:disabled="isSubmit"
					>检测</a-button
				>
				<p class="tip" style="color: #f3ac00; font-size: 12px">
					⚠️海外代理设备需在海外环境下才能检测成功，已是海外环境，但一直检测不通过，请及时联系右上角的客服进行处理
				</p>
			</div>
			<p v-if="isShowIpInfo" style="color: #4c84ffed">
				IP：{{
					JSON.parse(ipInfo.data).data.user_ip
				}}
				&nbsp;&nbsp;&nbsp;地区：{{
					JSON.parse(ipInfo.data).data.country
				}}/{{ JSON.parse(ipInfo.data).data.areacode }}
			</p>
			<div style="margin-top: 20px">
				<div style="line-height: 32px" class="timinha">登录账号</div>
				<a-input
					style="width: 440px; height: 36px"
					placeholder="代理登录账号"
					v-model="chec_account" />
			</div>
			<div style="margin-top: 20px">
				<div style="line-height: 32px" class="timinha">登录密码</div>
				<a-input
					style="width: 440px; height: 36px"
					placeholder="代理登录密码"
					v-model="chec_password" />
			</div>
			<div style="margin-top: 20px">
				<div style="line-height: 32px" class="timinha">刷新url</div>
				<a-input
					style="width: 440px; height: 36px"
					placeholder="刷新url"
					v-model="device_refresh_url" />
			</div>
		</a-modal>
	</div>
</template>

<script>
import { addowndevic, updatendevic } from "@/api/equipment.js";
export default {
	name: "AddDevice",
	props: ["deviceInfo"],
	data() {
		return {
			addhttplist: ["http", "https", "socks5"],
			chec_name: "",
			chec_attr: "staticState",
			chec_type: undefined,
			chec_ip: "",
			chec_port: "",
			chec_account: "",
			chec_password: "",
			isCheckLoading: false, //检测按钮的加载状态
			isCheckAvailable: false, //标识当前设备是否检测通过
			isShowIpInfo: false, //是否展示检测后的ip信息
			isSubmit: false, //是否正在点击确定按钮，点击确定按钮时禁用检测连接按钮
			timeID: "",
			device_refresh_url: "",
		};
	},
	created() {
		if (this.deviceInfo) {
			//如果有设备信息，说明是编辑设备
			this.isCheckAvailable = true;
			this.chec_name = this.deviceInfo.device_name;
			this.chec_type = this.deviceInfo.net_type;
			this.chec_ip = this.deviceInfo.device_ip;
			this.chec_port = this.deviceInfo.ip_port;
			this.chec_account = this.deviceInfo.account;
			this.chec_password = this.deviceInfo.password;
			this.device_refresh_url = this.deviceInfo.device_refresh_url;
		}
	},
	computed: {
		ipInfo() {
			return this.$store.state.ipInfo;
		},
		chec_info() {
			return {
				chec_type: this.chec_type,
				chec_ip: this.chec_ip,
				chec_port: this.chec_port,
				chec_account: this.chec_account,
				chec_password: this.chec_password,
			};
		},
	},
	watch: {
		chec_info() {
			this.isCheckAvailable = false;
			this.isShowIpInfo = false;
			if (this.judgeDeviceInfo()) {
				this.isCheckAvailable = true;
			}
		},
		//监听websocket返回的ip检测信息，如果成功则发送添加自有设备的请求
		async ipInfo(newVal) {
			console.log(newVal);
			if (this.multiAddVisible) return; //如果为批量添加则阻止该页面添加事件
			if (newVal.device_name.split(this.timeID)[0] != this.chec_name)
				return; //如果去除timeID后名称不匹配，阻止
			if (newVal.is_success == "1") {
				if (this.isCheckLoading) {
					this.$message.success("检测成功，设备可用");
					this.isCheckLoading = false;
					this.isCheckAvailable = true;
					newVal.data && (this.isShowIpInfo = true);
					return;
				}
				// let { data } = await addowndevic({
				//   device_name: this.chec_name,
				//   net_type: this.chec_type,
				//   device_ip: this.chec_ip,
				//   user_ip: JSON.parse(newVal.data).data.user_ip,
				//   ip_port: this.chec_port,
				//   account: this.chec_account,
				//   password: this.chec_password,
				//   // device_id: this.check_device.id,
				//   // device_name: this.checkdevice_newname,
				// });
				// if (data.code == 200) {
				//   this.chec_name = "";
				//   this.chec_type = undefined;
				//   this.chec_ip = "";
				//   this.chec_port = "";
				//   this.chec_account = "";
				//   this.chec_password = "";
				//   this.isSubmit = false;
				//   this.editname_ziyou = false;
				//   this.fetchList(2);
				//   // this.fetchList();
				//   this.$message.success("操作成功");
				// }
			} else {
				this.isCheckLoading = false;
				this.isCheckAvailable = false;
				this.isShowIpInfo = false;
				this.isSubmit = false;
				this.$message.error(
					"设备信息检测失败,请检查信息是否正确，重新录入！"
				);
			}
		},
	},
	methods: {
		//判断信息是否和初始值一样
		judgeDeviceInfo() {
			if (!this.deviceInfo) {
				return false;
			} else {
				return (
					this.chec_type == this.deviceInfo.net_type &&
					this.chec_ip == this.deviceInfo.device_ip &&
					this.chec_port == this.deviceInfo.ip_port &&
					this.chec_account == this.deviceInfo.account &&
					this.chec_password == this.deviceInfo.password
				);
			}
		},
		handleIP(e) {
			//快速识别以逗号分割的ip链接
			e.target.value = this.chec_ip;
			const IPInfo = e.target.value.split(":");
			this.chec_ip = IPInfo[0] || "";
			this.chec_port = IPInfo[1] || "";
			this.chec_account = IPInfo[2] || "";
			this.chec_password = IPInfo[3] || "";
		},
		handlePort(e) {
			e.target.value = this.chec_port;
		},
		editziyou_handleCancel() {
			this.$emit("cancel");
		},
		//检测自有设备ip代理信息
		checkIp() {
			if (!this.chec_name) {
				this.$message.error("请输入设备名称！");
				return;
			}
			if (!this.chec_type) {
				this.$message.error("请选择网络类型!");
				return;
			}
			if (!this.chec_ip) {
				this.$message.error("请输入设备地址或域名!");
				return;
			}
			if (!this.chec_port) {
				this.$message.error("请输入端口号!");
				return;
			}
			this.isCheckLoading = true;

			this.get_client_params();
		},
		async editname_handleOk() {
			if (this.isSubmit) {
				return;
			}
			if (!this.chec_name) {
				this.$message.error("请输入设备名称！");
				return;
			}
			if (!this.chec_type) {
				this.$message.error("请选择网络类型!");
				return;
			}
			if (!this.chec_ip) {
				this.$message.error("请输入设备地址或域名!");
				return;
			}
			if (!this.chec_port) {
				this.$message.error("请输入端口号!");
				return;
			}
			this.isSubmit = true;

			if (this.isCheckAvailable) {
				let { data } = this.deviceInfo
					? await updatendevic({
							id: this.deviceInfo.id,
							device_name: this.chec_name,
							net_type: this.chec_type,
							device_ip: this.chec_ip,
							ip_port: this.chec_port,
							account: this.chec_account,
							password: this.chec_password,
							user_ip: this.judgeDeviceInfo()
								? this.deviceInfo.user_ip
								: JSON.parse(this.ipInfo.data).data.user_ip,
							device_refresh_url: this.device_refresh_url,
					  })
					: await addowndevic({
							device_name: this.chec_name,
							net_type: this.chec_type,
							device_ip: this.chec_ip,
							user_ip: JSON.parse(this.ipInfo.data).data.user_ip,
							ip_port: this.chec_port,
							account: this.chec_account,
							password: this.chec_password,
							device_refresh_url: this.device_refresh_url,
							// device_id: this.check_device.id,
							// device_name: this.checkdevice_newname,
					  });
				if (data.code == 200) {
					this.$emit("fetchList");
					this.$emit("cancel");
					//   this.fetchList(2);
					this.$message.success("操作成功");
				} else {
					this.isSubmit = false;
				}
			} else {
				this.$message.info("正在检测设备可用性");
				this.get_client_params();
			}

			// this.$store.commit("updateIp", {});
		},
		get_client_params() {
			const token = localStorage.token;
			if (!token) {
				this.$message.error("请先登录");
				return;
			}
			this.timeID = token.slice(token.length - 4) + Date.now(); //生成时间戳作为每台的设备的唯一标识
			let webobj = JSON.stringify({
				allData: [
					{
						device_name: this.chec_name + this.timeID,
						proxy_type: this.chec_type,
						proxy_ip: this.chec_ip,
						proxy_port: this.chec_port + "",
						proxy_user_name: this.chec_account,
						proxy_password: this.chec_password,
					},
				],
				message: "/connection/testProxy",
			});
			this.websocketData = webobj;
			let c_1 = this.websocketData;
			let b_1 = this.$encrypt(c_1);
			console.log(JSON.parse(c_1));
			this.$store.state.websocket.send(b_1);
		},
	},
};
</script>

<style lang="less" scoped></style>
