import request from "@/utils/request";
import axios from "axios";
export const getList = data => {
	return request({
		url: "/client_v1/device",
		method: "POST",
		data,
	});
};
// 添加自有设备
export const addowndevic = data => {
	return request({
		url: "/client_v1/device/create-own-device",
		method: "POST",
		data,
	});
};
// 获取编辑自有设备信息
export const getDeviceInfo = data => {
	return request({
		url: "/client_v1/device/own-device-info",
		method: "POST",
		data,
	});
};
// 编辑自有设备
export const updatendevic = data => {
	return request({
		url: "/client_v1/device/update-own-device",
		method: "POST",
		data,
	});
};
// 查看设备开通状态
// export const checkDeviceStatus = (data) => {
//   return axios.post("/client_v1/device/reset-device", data, { timeout: 3 })
// }
export const checkDeviceStatus = data => {
	return request({
		url: "/client_v1/device/reset-device",
		method: "POST",
		data,
		timeout: 2000,
	});
};

// 筛选的选项
export const getListChoose = data => {
	return request({
		url: "/client_v1/device/device-choose-data",
		method: "POST",
		data,
	});
};

export const purchase_device_list = data => {
	return request({
		url: "/client_v1/device/purchase-device-list",
		method: "POST",
		data,
	});
};

//已绑定环境列表
export const device_bind_environment_list = data => {
	return request({
		url: "/client_v1/device/bind-environment-list",
		method: "POST",
		data,
	});
};

// 批量绑定设备
export const device_bind_device_more = data => {
	return request({
		url: "/client_v1/environment/bind-device-more",
		method: "POST",
		data,
	});
};

// 批量接绑定设备
export const device_unbind_device_more = data => {
	return request({
		url: "/client_v1/environment/unbind-device-more",
		method: "POST",
		data,
	});
};

//绑定历史记录
export const device_bind_history_record = data => {
	return request({
		url: "/client_v1/device/bind-history-record",
		method: "POST",
		data,
	});
};

//设备操作日志
export const device_operate_log_list = data => {
	return request({
		url: "/client_v1/device/operate-log-list",
		method: "POST",
		data,
	});
};

//设备归属列表
export const device_devicenetworkregionlist = data => {
	return request({
		url: "/client_v1/device/device-network-region-list",
		method: "POST",
		data,
	});
};

//取消/开启自动续费
export const device_cancelrenewstatus = data => {
	return request({
		url: "/client_v1/device/cancel-renew-status",
		method: "POST",
		data,
	});
};

// 自动续费 批量
export const device_cancelrenewstatus_mora = data => {
	return request({
		url: "/client_v1/device/cancel-renew-status-more",
		method: "POST",
		data,
	});
};

//编辑设备名称
export const device_updatedevicename = data => {
	return request({
		url: "/client_v1/device/update-device-name",
		method: "POST",
		data,
	});
};

//设备绑定环境
export const device_bindenvironment = data => {
	return request({
		url: "/client_v1/device/bind-environment",
		method: "POST",
		data,
	});
};

//设备解绑环境
export const device_unbindenvironment = data => {
	return request({
		url: "/client_v1/device/unbind-environment",
		method: "POST",
		data,
	});
};

//批量删除设备(软删除)
export const device_deletedevicemore = data => {
	return request({
		url: "/client_v1/device/delete-device-more",
		method: "POST",
		data,
	});
};
//单个删除设备(软删除)
export const device_dcemore = data => {
	return request({
		url: "/client_v1/device/delete-device",
		method: "POST",
		data,
	});
};

//设备未绑定环境列表
export const device_bindnoenvironmentlist = data => {
	return request({
		url: "/client_v1/device/bind-no-environment-list",
		method: "POST",
		data,
	});
};

//设备标签列表
export const device_devicetaglist = data => {
	return request({
		url: "/client_v1/device/device-tag-list",
		method: "POST",
		data,
	});
};

//添加设备标签
export const device_createdevicetag = data => {
	return request({
		url: "/client_v1/device/create-device-tag",
		method: "POST",
		data,
	});
};

//批量删除设备标签
export const device_deletedevicetagmore = data => {
	return request({
		url: "/client_v1/device/delete-device-tag-more",
		method: "POST",
		data,
	});
};

//编辑设备标签
export const device_updatedevicetag = data => {
	return request({
		url: "/client_v1/device/update-device-tag",
		method: "POST",
		data,
	});
};

//编辑设备绑定标签
export const device_devicebindtag = data => {
	return request({
		url: "/client_v1/device/device-bind-tag",
		method: "POST",
		data,
	});
};

//设备回收站批量恢复设备
export const recovery_device_more = data => {
	return request({
		url: "/client_v1/device/recovery-device-more",
		method: "POST",
		data,
	});
};

// 设备购买页面接口
// 初始信息
export const initDeviceApi = data => {
	return request({
		url: "/client_v1/device-v2/init-device",
		method: "POST",
		data,
	});
};

//购买设备可选套餐地区
export const deviceAreaApi = data => {
	return request({
		url: "/client_v1/device-v2/device-region",
		method: "POST",
		data,
	});
};

// 购买设备下单
export const createOrderApi = data => {
	return request({
		url: "/client_v1/order/create",
		method: "POST",
		data,
	});
};

// 购买时长
export const durationListApi = data => {
	return request({
		url: "/client_v1/device/purchase-duration-list",
		method: "POST",
		data,
	});
};

// 支付方式
export const payMethodsApi = data => {
	return request({
		url: "/client_v1/device/pay-channel",
		method: "POST",
		data,
	});
};

// 用户优惠券列表//优惠券列表
export const couponListApi = data => {
	return request({
		url: "/client_v1/coupon/list",
		method: "POST",
		data,
	});
};

// 获取用户信息
export const userInfoApi = data => {
	return request({
		url: "/client_v1/user/info",
		method: "POST",
		data,
	});
};

// 余额支付
export const balancePayApi = data => {
	return request({
		url: "/client_v1/pay/balance",
		method: "POST",
		data,
	});
};

// 微信支付宝支付
export const onlinePayApi = data => {
	return request({
		url: "/client_v1/pay",
		method: "POST",
		data,
	});
};

// 查询订单详情
export const orderInfoApi = data => {
	return request({
		url: "/client_v1/order/order-info",
		method: "POST",
		data,
	});
};

// 刷新支付宝二维码
export const refreshQrApi = data => {
	return request({
		url: "/client_v1/pay/refresh-dyn-qr-key",
		method: "POST",
		data,
	});
};
