<template>
	<div class="enewal">
		<div class="noe1">购买设备服务协议</div>
		<div class="noe2">
			本服务协议是快洋淘浏览器与您就购买设备服务的相关事项所订立的有效合约。您通过盖章、网络页面点击确认或以其他方式选择接受本服务协议，包括但不限于未点击确认本服务协议而事实上使用了快洋淘浏览器设备服务，即表示您与已达成协议并同意接受本服务协议的全部约定内容。如若双方盖章文本与网络页面点击确认或以其他方式选择接受之服务协议文本，存有不一致之处，以双方盖章文本为准。
			关于本服务协议，提示您特别关注限制、免责条款，快洋淘浏览器对您违规、违约行为的认定处理条款，以及管辖法院的选择条款等。限制、免责条款可能以加粗或加下划线形式提示您注意。在接受本服务协议之前，请您仔细阅读本服务协议的全部内容。如果您对本服务协议的条款有疑问的，请通过快洋淘浏览器相关业务部门进行询问，快洋淘浏览器将向您解释条款内容。如果您不同意本服务协议的任意内容，或者无法准确理解快洋淘浏览器对条款的解释，请不要进行后续操作。
		</div>
		<div class="noetie">1. 服务协议</div>
		<div class="noezi">
			1.1.本协议中“服务”指：快洋淘浏览器向您提供https://www.yangtao.com网站上所展示的设备服务以及相关的技术及网络支持服务。
		</div>
		<div class="noezi">
			1.2.快洋淘浏览器提供的服务必须符合本服务协议的约定。
		</div>
		<div class="noetie">2.服务费用</div>
		<div class="noezi">
			2.1.服务费用将在您订购页面予以列明公示，您可自行选择具体服务类型并按列明的价格予以支付。
		</div>
		<div class="noezi">
			2.2.付费方式：具体扣费规则请查看订购页面公告且以页面公布的当时有效的计费模式、标准为准。
		</div>
		<div class="noezi">
			2.3.快洋淘浏览器保留在您未按照约定支付全部费用之前不向您提供服务和/或技术支持，或者终止服务和/或技术支持的权利。同时，快洋淘浏览器保留对后付费服务中的欠费行为追究法律责任的权利。
		</div>
		<div class="noetie">3.权利义务</div>
		<div class="noezi">3.1.您的权利、义务</div>
		<div class="noezi">
			3.1.1.您同意遵守本服务协议以及服务展示页面的相关管理规范及流程。您了解上述协议及规范等的内容可能会不时变更。如本服务协议的任何内容发生变动，快洋淘浏览器应通过提前30天在https://www.yangtao.com网站的适当版面公告向您提示修改内容。如您不同意快洋淘浏览器对本服务协议相关条款所做的修改，您有权停止使用快洋淘浏览器的设备服务，此等情况下，快洋淘浏览器应与您进行服务费结算（如有）。如您继续使用快洋淘浏览器设备服务，则视为您接受快洋淘浏览器对本服务协议相关条款所做的修改。
		</div>
		<div class="noezi">
			3.1.2您应按照快洋淘浏览器的页面提示及本服务协议的约定支付相应服务费用。
		</div>
		<div class="noezi">
			3.1.3.您承诺，未经快洋淘浏览器书面同意您不得将快洋淘浏览器设备服务用于跨境电商店铺管理以外的其他用途，且您在使用设备服务过程中：
		</div>
		<div class="noezi">
			3.1.3.1.您不应进行任何破坏或试图破坏网络安全的行为（包括但不限于钓鱼，黑客，网络诈骗，网站或空间中含有或涉嫌散播：病毒、木马、恶意代码，及通过虚拟服务器对其他网站、服务器进行涉嫌攻击行为如扫描、嗅探、ARP欺骗、DDOS等）；
		</div>
		<div class="noezi">
			3.1.3.2.您不应进行任何改变或试图改变快洋淘浏览器提供的系统配置或破坏系统安全及网络安全的行为；
		</div>
		<div class="noezi">
			3.1.3.3.您不应修改、翻译、改编、出租、转许可、在信息网络上传播或转让快洋淘浏览器提供的软件或服务，也不得逆向工程、反编译或试图以其他方式发现快洋淘浏览器提供的软件的源代码；
		</div>
		<div class="noezi">
			3.1.3.4.非经快洋淘浏览器事先书面同意，您不应复制、传播、转让、许可或提供他人使用快洋淘浏览器提供的设备服务；
		</div>
		<div class="noezi">
			3.1.3.5.不散布电子邮件广告、垃圾邮件（SPAM）：不利用快洋淘浏览器提供的设备服务散发大量不受欢迎的或者未经请求的电子邮件、电子广告或包含反动、色情等有害信息的电子邮件；
		</div>
		<div class="noezi">
			3.1.3.6.不利用快洋淘浏览器提供的资源和服务上传（Upload）、下载（download）、储存、发布如下信息或者内容，不为他人发布该等信息提供任何便利（包括但不限于设置URL、BANNER链接等）:
		</div>
		<div class="noezi">3.1.3.6.1.违反国家规定的政治宣传和/或新闻信息；</div>
		<div class="noezi">3.1.3.6.2.涉及国家秘密和/或安全的信息；</div>
		<div class="noezi">
			3.1.3.6.3.封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；
		</div>
		<div class="noezi">
			3.1.3.6.4.博彩有奖、赌博游戏、“私服”、“外挂”等非法互联网出版活动；
		</div>
		<div class="noezi">3.1.3.6.5.违反国家民族和宗教政策的信息；</div>
		<div class="noezi">3.1.3.6.6.妨碍互联网运行安全的信息；</div>
		<div class="noezi">
			3.1.3.6.7.侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容；
		</div>
		<div class="noezi">
			3.1.3.6.8.其他违反法律法规、部门规章或国家政策的内容。
		</div>
		<div class="noezi">
			3.1.3.7.您不应以任何将会违反国家、地方法律法规、行业惯例和社会公共道德，及影响、损害或可能影响、损害快洋淘浏览器利益的方式或目的使用设备服务。
		</div>
		<div class="noezi">
			3.1.3.8.您在使用快洋淘浏览器设备服务过程中应遵守所有可适用的法律法规及本协议的规定，不得发生任何违法、违反本协议或侵犯第三方合法权益的行为；您应对您帐户项下的全部行为承担相应法律责任。
		</div>
		<div class="noezi">
			3.1.3.9.如您违反本协议或《快洋淘浏览器用户协议》任何条款或可适用的法律法规，或发生任何第三方侵权投诉，经快洋淘浏览器书面通知，且您无相反证据证明其无相关违法违约行为的，快洋淘浏览器将有权限制、封禁您在快洋淘浏览器的帐号，及暂停或终止提供服务的部分或全部功能，并要求乙方赔偿因此给快洋淘浏览器造成的一切经济损失。
		</div>
		<div class="noezi">
			3.1.3.10.如快洋淘浏览器发现您违反上述条款的约定，有权根据情况采取相应的处理措施，包括但不限于立即终止服务、中止服务或删除相应信息等。如果第三方机构或个人对您提出质疑或投诉，快洋淘浏览器将通知您，您有责任在规定时间内进行说明并出具证明材料，如您未能提供相反证据或您逾期未能反馈的，快洋淘浏览器将有权限制、封禁您在快洋淘浏览器的帐号，及暂停或终止提供服务的部分或全部功能，并要求您赔偿因此给快洋淘浏览器造成的一切经济损失。因您未及时更新联系方式或联系方式不正确而致使未能联系到您的，亦视为您逾期未能反馈。
		</div>
		<div class="noezi">3.2.快洋淘浏览器的权利、义务</div>
		<div class="noezi">3.2.1.快洋淘浏览器应按照服务协议约定提供服务。</div>
		<div class="noezi">
			3.2.2.服务期限内，快洋淘浏览器将为您提供如下客户服务：
		</div>
		<div class="noezi">
			3.2.2.1.快洋淘浏览器为付费用户提供7×12售后故障服务，并为付费用户提供有效的联系方式并保证付费用户能够联系到故障联系人。故障联系人在明确故障后及时进行反馈；
		</div>
		<div class="noezi">
			3.2.2.2.快洋淘浏览器提供7×12小时的在线工单服务系统，解答客户在使用中的问题。
		</div>
		<div class="noezi">
			3.2.3.快洋淘浏览器将消除您非人为操作所出现的故障，但因您原因和/或不可抗力以及非快洋淘浏览器控制范围之内的事项除外。
		</div>
		<div class="noetie">4.保密条款</div>
		<div class="noezi">
			4.1.保密资料指由一方向另一方披露的所有技术及非技术信息(包括但不限于产品资料，产品计划，价格，财务及营销规划，业务战略，客户信息，客户数据，研发资料，软件硬件，API应用数据接口，技术说明，设计，特殊公式，特殊算法等)。
		</div>
		<div class="noezi">
			4.2.本服务协议任何一方同意对获悉的对方之上述保密资料予以保密，并严格限制接触上述保密资料的员工遵守本条之保密义务。除非国家机关依法强制要求或上述保密资料已经进入公有领域外，接受保密资料的一方不得对外披露。
		</div>
		<div class="noezi">
			4.3.本服务协议双方明确认可保密资料是双方的重点保密信息并是各自的重要资产，本服务协议双方同意尽最大的努力保护上述保密资料等不被披露。一旦发现有上述保密资料泄露事件，双方应合作采取一切合理措施避免或者减轻损害后果的产生。
		</div>
		<div class="noezi">4.4.本条款不因本服务协议的终止而失效。</div>
		<div class="noetie">5.期限与终止</div>
		<div class="noezi">
			5.1.您自开通之日起即可使用快洋淘浏览器设备服务。
		</div>
		<div class="noezi">
			5.2.您应保持账户余额充足以确保服务的持续使用，如您发生欠费，快洋淘浏览器有权停止向您提供本服务协议项下的服务。
		</div>
		<div class="noezi">5.3.发生下列情形，服务协议将被终止：</div>
		<div class="noezi">5.3.1.双方协商一致提前终止的；</div>
		<div class="noezi">
			5.3.2.您严重违反本服务协议（包括但不限于a.您未按照约定履行付款义务，及/或b.您严重违反法律规定等），快洋淘浏览器有权暂停、终止服务，并有权提前终止本服务协议，如您仍应支付尚未支付的服务费用，您仍应支付所欠费用。
		</div>
		<div class="noezi">
			5.3.3.您理解并充分认可，虽然快洋淘浏览器云已经建立（并将根据技术的发展不断完善）必要的技术措施来防御包括计算机病毒、网络入侵和攻击破坏（包括但不限于DDOS）等危害网络安全事项或行为（以下统称该等行为），但鉴于网络安全技术的局限性、相对性以及该等行为的不可预见性，因此如因您网站遭遇该等行为而给快洋淘浏览器或者快洋淘浏览器的其他的网络或服务器（包括但不限于本地及外地和国际的网络、服务器等）带来危害，或影响快洋淘浏览器与国际互联网或者快洋淘浏览器与特定网络、服务器及快洋淘浏览器内部的通畅联系，快洋淘浏览器可决定暂停或终止服务。如果终止服务的，将按照实际提供服务计算服务费用，并且您仍应支付您尚未支付的服务费用（如有）。
		</div>
		<div class="noezi">
			5.3.4.快洋淘浏览器可提前30天在https://www.yangtao.com上通告或给您发站内通知或书面通知的方式终止本服务协议。届时快洋淘浏览器应将您已支付但未消费的款项退还至您的快洋淘浏览器账户（如有）。
		</div>
		<div class="noetie">6.服务协议</div>

		<div class="noezi">
			6.1.本服务协议任何一方违约均须依法承担违约责任。
		</div>
		<div class="noezi">
			6.2.您理解，鉴于计算机、互联网的特殊性，下述情况不属于快洋淘浏览器违约：
		</div>
		<div class="noezi">
			6.2.1.快洋淘浏览器在进行服务升级、维护时，需要短时间中断服务；
		</div>
		<div class="noezi">
			6.2.2.由于Internet上的通路阻塞造成您网站访问速度下降。
		</div>
		<div class="noezi">
			6.3.您理解，鉴于计算机、互联网的特殊性，下述情况不属于快洋淘浏览器违约：在任何情况下，快洋淘浏览器均不对任何间接性、后果性、惩戒性、偶然性、特殊性的损害，包括您使用快洋淘浏览器服务而遭受的利润损失承担责任（即使您已被告知该等损失的可能性）。
		</div>
		<div class="noezi">
			6.4.在任何情况下，快洋淘浏览器对本服务协议所承担的违约赔偿责任总额不超过违约服务对应之服务费总额。
		</div>
		<div class="noetie">7.不可抗力</div>

		<div class="noezi">
			7.1.因不可抗力或者其他意外事件，使得本服务协议的履行不可能、不必要或者无意义的，遭受不可抗力、意外事件的一方不承担责任。
		</div>
		<div class="noezi">
			7.2.不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动乱、政府行为、电信主干线路中断、黑客、网路堵塞、电信部门技术调整和政府管制等。
		</div>
		<div class="noetie">8.法律适用及争议解决</div>

		<div class="noezi">8.1.本服务协议受中华人民共和国法律管辖。</div>
		<div class="noezi">
			8.2.在执行本服务协议过程中如发生纠纷，双方应及时协商解决。协商不成时，任何一方可直接向广东省深圳市龙岗区人民法院提起诉讼。
		</div>
		<div class="noetie">9.附则</div>
		<div class="noezi">
			9.1.快洋淘浏览器在https://www.yangtao.com相关页面上的服务说明、价格说明和您确认同意的订购页面是本服务协议不可分割的一部分。如果https://www.yangtao.com相关页面上的服务说明、价格说明和您确认同意的订购页面与本服务协议有不一致之处，以本服务协议为准。
		</div>
		<div class="noezi">
			9.2.如本服务协议与《快洋淘浏览器用户服务协议》有不一致之处，以《购买设备服务协议》条款为准。
		</div>
		<div class="noezi">
			9.3.快洋淘浏览器有权以提前30天在https://www.yangtao.com上公布、或给您发网站内通知或书面通知的方式将本服务协议的权利义务全部或者部分转移给快洋淘浏览器的关联公司。
		</div>
		<div class="noezi">
			9.4.如果任何条款在性质上或其他方面理应地在此协议终止时继续存在，那么应视为继续存在的条款，这些条款包括但不局限于保证条款、保密条款、知识产权条款、法律适用及争议解决条款。注：除本协议规定外，设备的具体适用场景、设备的具体使用规则，需以所授权平台及实际适用平台的规则为准。
		</div>
	</div>
</template>
<script>
export default {
	name: "fuwu",
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {},
};
</script>
<style lang="less" scoped>
.enewal {
	// width: 1879px;
	// height: 969px;
	padding-bottom: 54px;
	background: #fff;
	margin: auto;
	overflow: hidden;
	.noe1 {
		width: 192px;
		height: 33px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #2c354b;
		line-height: 33px;
		margin: auto;
		margin-top: 34px;
	}
	.noe2 {
		width: 1759px;
		height: 96px;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #2c354b;
		line-height: 24px;
		margin: auto;
		margin-top: 31px;
	}
	.noetie {
		width: 1120px;
		// height: 24px;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #2c354b;
		line-height: 24px;
		margin-left: 60px;
		margin-top: 28px;
	}
	.noezi {
		width: 1120px;
		// height: 24px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2c354b;
		line-height: 30px;
		margin-left: 60px;
		margin-top: 12px;
	}
}
</style>
