export function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
}
export function importExcel(file, XLSX, type) {
	function formatExcel(item) {
		let mapObj = {};
		if (type == "environment") {
			mapObj = {
				环境名称: "env_name",
				平台名称: "site",
				平台所属国家: "country",
				绑定设备: "device_name",
				环境标签: "tagNames",
				授权成员: "memberNames",
				企业简称: "business_short",
				店铺账号: "shop_account",
				店铺密码: "shop_password",
				附加插件: "add_extension",
			};
		} else {
			mapObj = {
				设备名称: "device_name",
				代理类型: "net_type",
				设备地址: "device_ip",
				端口号: "ip_port",
				登录账号: "account",
				登录密码: "password",
			};
		}
		if (Object.keys(item).some(i => !Object.keys(mapObj).includes(i))) return false;
		const newItem = {};
		for (const key in mapObj) {
			if (item[key]) {
				newItem[mapObj[key]] = item[key] + "";
			}
		}
		return newItem;
	}
	return new Promise((resolve, reject) => {
		if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
			// return alert("上传格式不正确，请上传xls或者xlsx格式");
			return;
		}
		const fileReader = new FileReader();
		fileReader.onload = ev => {
			try {
				const data = ev.target.result;
				const workbook = XLSX.read(data, {
					type: "binary",
				});
				const wsname = workbook.SheetNames[0]; //取第一张表，wb.SheetNames[0]是获取Sheets中第一个Sheet的名字
				const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
					defval: "",
					range: 2,
				}); //生成json表格内容，wb.Sheets[Sheet名]获取第一个Sheet的数据
				const excellist = []; //清空接收数据
				//编辑数据
				for (var i = 0; i < ws.length; i++) {
					const formatResult = formatExcel(ws[i]);
					if (formatResult) {
						excellist.push(formatResult);
					} else {
						reject("文件不符合模板要求");
					}
				}
				if (!excellist.length) reject("文件内容为空");
				resolve(excellist);
			} catch (e) {
				return alert("读取失败!");
			}
		};
		fileReader.readAsBinaryString(file);
	});
}
//导出为excel文件
export function exportExcel(XLSX, list, header) {
	var wb = XLSX.utils.book_new();
	var ws = XLSX.utils.json_to_sheet(list, {
		header: header,
		skipHeader: true,
	});
	XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
	const data = XLSX.write(wb, { type: "array", bootType: "xlsx" });
	var blob = new Blob([data], { type: "application/octet-stream" });
	var url = URL.createObjectURL(blob);
	var a = document.createElement("a");
	a.download = "批量导入结果.xlsx";
	a.href = url;
	document.body.appendChild(a);
	a.click();
	URL.revokeObjectURL(url);
}
