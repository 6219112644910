<template>
	<div class="equipment">
		<div class="menu">
			<a-menu
				style="width: 210px; height: 100%"
				mode="inline"
				:default-selected-keys="['1']"
				:selected-keys="[current]"
				@click="handleClick">
				<a-menu-item key="1" class="menu_one">
					<div class="my_equipment"></div>
					<div class="title">我的设备</div>
				</a-menu-item>
				<a-menu-item key="2" class="menu_one">
					<div class="my_equipment will_expire"></div>
					<div class="title">即将过期设备</div>
					<div class="title wenzihuang" v-if="about_expire != 0">
						{{ about_expire == 0 ? "" : about_expire }}
					</div>
				</a-menu-item>
				<a-menu-item key="3" class="menu_one">
					<div class="my_equipment expired"></div>
					<div class="title">已过期设备</div>
					<div class="title wenzihuang" v-if="expired != 0">
						{{ expired == 0 ? "" : expired }}
					</div>
				</a-menu-item>
				<!-- <a-menu-item key="4" class="menu_one">
          <div class="my_equipment bin"></div>
          <div class="title">设备回收站</div>
        </a-menu-item> -->
				<a-menu-item key="5" class="menu_one">
					<div class="my_equipment bound"></div>
					<div class="title">待绑定环境设备</div>
					<div class="title wenzihuang" v-if="no_bind_env != 0">
						{{ no_bind_env == 0 ? "" : no_bind_env }}
					</div>
				</a-menu-item>
				<!-- <a-menu-item key="6" class="menu_one">
					<div class="my_equipment renewal"></div>
					<div class="title">自动续费的设备</div>
				</a-menu-item> -->
			</a-menu>
		</div>

		<div class="content">
			<div class="search_panel">
				<a-button type="primary" class="eq_buy_btn" @click="buyEq"
					>购买设备</a-button
				>
				<!-- <a-button class="eq_buy_btn" @click="show_editname_ziyou"
          >添加自有设备</a-button
        > -->
				<a-dropdown style="float: left; margin-left: 16px">
					<a-menu slot="overlay" @click="handleMenuClick">
						<a-menu-item key="single">
							<a-icon type="plus" />单个添加
						</a-menu-item>
						<a-menu-item key="multi">
							<a-icon type="folder-add" />批量导入
						</a-menu-item>
					</a-menu>
					<a-button> 添加自有设备 <a-icon type="down" /> </a-button>
				</a-dropdown>
				<a-button class="eq_buy_btn" @click="show_tagmanage = true"
					>设备标签管理</a-button
				>

				<div class="fieldBox">
					<a-button class="eq_buy_btn">自定义字段</a-button>
					<a-select
						class="field"
						v-model="listValue"
						@change="fieldChange"
						mode="multiple"
						placeholder="自定义字段"
						option-label-prop="label">
						<a-select-option
							v-for="item in listF"
							:key="item.name"
							:value="item.name"
							:label="item.name">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</div>

				<div class="fieldBox">
					<a-button
						class="eq_buy_btn"
						:class="{ disabled: !selectedRowKeys.length }"
						>批量操作</a-button
					>
					<a-select
						class="batch"
						:disabled="!selectedRowKeys.length"
						v-model="listValue"
						placeholder="批量操作">
						<!-- <a-select-option key="name" @click="editorName">
              编辑设备名
            </a-select-option> -->
						<!-- <a-select-option
							class="pil"
							key="xufei"
							@click="autoRenewal">
							自动续费
						</a-select-option> -->
						<a-select-option key="addtag" @click="addMoreTag">
							新增标签
						</a-select-option>
						<a-select-option key="deltag" @click="delMoreTag">
							移除标签
						</a-select-option>
						<a-select-option key="emtag" @click="emptyMoreTag">
							清空标签
						</a-select-option>
						<a-select-option key="del" @click="delDelete">
							删除设备
						</a-select-option>
					</a-select>
				</div>
				<a-modal
					title="删除设备"
					:visible="visible"
					:confirm-loading="confirmLoading"
					@ok="handleOk"
					@cancel="handleCancel">
					<p>
						确定删除这{{
							selectedRowKeys.length
						}}个设备吗？提示：若删除的是未过期设备，在设备过期前可在回收站找回。
					</p>
				</a-modal>
				<!-- <a-button
          type="primary"
          class="eq_buy_btn"
          :disabled="selectedRowKeys.length === 0"
          @click="renewalEq"
          >续费设备</a-button
        > -->

				<div class="right">
					<a-button @click="showDrawer" class="fliter_drawer"
						><i></i>筛选</a-button
					>
					<a-input-search
						class="eq_buy_btn"
						style="width: 460px"
						placeholder="设备名称/设备信息/归属"
						@search="onSearchKey" />
				</div>
			</div>
			<div class="zhonjian1">
				<span
					class="yanzs"
					:class="tupecss == 1 ? 'lanse' : ''"
					@click="fetchList(1)"
					>平台设备 {{ pinnum ? `(${pinnum})` : "" }}
				</span>
				<!-- {{ pinnum }}   {{ zunum }}-->
				<span
					v-if="isjja"
					class="shebiea"
					:class="tupecss == 2 ? 'lanse' : ''"
					@click="fetchList(2)"
					>自有设备 {{ zunum ? `(${zunum})` : "" }}
				</span>
			</div>
			<div class="eq_info">
				<!-- <no-equipment v-if="!has_device"></no-equipment> -->

				<a-table
					:row-selection="{
						selectedRowKeys: selectedRowKeys,
						onChange: onSelectChange,
					}"
					:row-key="record => record.id"
					:columns="columns"
					:data-source="list"
					:pagination="pagination"
					:loading="loading"
					@change="handleTableChange"
					:scroll="{ x: 1200 }">
					<div slot="cell_under" slot-scope="text, record">
						{{ record.region }} , {{ record.area }}
					</div>

					<div slot="cell_name" slot-scope="text, record">
						{{ format_name(record) }}
					</div>

					<!-- <div slot="device_net" slot-scope="text, record">
            {{ record.product }}
          </div> -->

					<div slot="cell_remote" slot-scope="text">
						{{ formate_remote(text) }}
					</div>

					<div slot="cell_device_remote" slot-scope="text">
						{{ text ? "不可远程" : "可远程" }}
					</div>

					<div slot="cell_renew_status" slot-scope="text">
						{{ text == 0 ? "未开启" : "开启" }}
					</div>

					<div slot="cell_tags" slot-scope="text">
						{{ formate_tags(text) }}
					</div>

					<div slot="cell_renew" slot-scope="text">
						{{ formate_renew(text) }}
					</div>

					<div slot="cell_status" slot-scope="text, record">
						{{ formate_status(text) }}
						<a-button
							v-show="text == 2"
							type="link"
							@click="checkDevice(record)"
							>查看</a-button
						>
					</div>

					<template slot="operation" slot-scope="text, record">
						<!-- <a-button type="primary" @click="onRenew(record)">续费</a-button> -->

						<a-button
							type="link"
							@click="show_detail(record)"
							class="view_btn"
							>详情</a-button
						>
						<!-- <a-button
							type="link"
							@click="renewal(record)"
							v-if="record.status == 0"
							class="view_btn"
							>续费</a-button
						> -->

						<a-popover
							placement="bottom"
							v-if="record.status != 4 || query.status != 4"
							trigger="hover"
							overlayClassName="table-popover">
							<!-- 平台 -->
							<div
								v-if="tupecss == 1"
								slot="content"
								@click="show_editname_pop(record)"
								class="popover_edit-content">
								<div>编辑设备名称</div>
							</div>
							<!-- 自有 -->
							<div
								v-if="tupecss == 2"
								slot="content"
								@click="show_editname_pop(record)"
								class="popover_edit-content">
								<div>编辑自有设备</div>
							</div>

							<!-- <div
								slot="content"
								v-if="
									record.renew_status == 0 &&
									record.status == 0
								"
								class="popover_edit-content"
								@click="change_autopay(record, 1)">
								<div>开启自动续费</div>
							</div> -->
							<div
								slot="content"
								v-if="record.renew_status == 1"
								@click="change_autopay(record, 0)"
								class="popover_edit-content">
								<div>关闭自动续费</div>
							</div>
							<div
								slot="content"
								v-if="record.status != 3"
								@click="open_binddevice_pop(record)"
								class="popover_edit-content">
								<div>绑定环境</div>
							</div>
							<div
								slot="content"
								@click="open_unbinddevice_pop(record)"
								class="popover_edit-content">
								<div>解绑环境</div>
							</div>
							<div
								slot="content"
								class="popover_edit-content"
								@click="open_edit_devicetag_pop(record)">
								<div>设备标签</div>
							</div>
							<div
								slot="content"
								class="popover_edit-content"
								@click="change_deletdevice_pop(record)">
								<div>删除设备</div>
							</div>

							<a-button
								type="link"
								class="view_btn mora"
								v-if="record.status != 4">
								更多
							</a-button>
						</a-popover>

						<a-button
							type="link"
							v-if="query.status == 4 && record.status == 4"
							@click="recover_device(record)"
							class="view_btn"
							>恢复
						</a-button>
					</template>
				</a-table>
			</div>
		</div>

		<a-modal
			title="筛选"
			:visible="drawer_visible"
			:width="520"
			@cancel="drawer_visible = false">
			<a-form-model
				:model="query"
				:label-col="labelCol"
				:wrapper-col="wrapperCol">
				<!-- <a-form-model-item label="搜索">
          <a-input v-model="query.keyword" placeholder="搜索" />
        </a-form-model-item> -->

				<a-form-model-item label="设备状态">
					<a-radio-group v-model="query.status">
						<a-radio value="0">正常</a-radio>
						<a-radio value="1">过期</a-radio>
						<a-radio value="2">待分配</a-radio>
						<a-radio value="3">故障</a-radio>
					</a-radio-group>
				</a-form-model-item>

				<a-form-model-item label="设备标签">
					<!-- <a-input v-model="query.tags" placeholder="请选择或搜索设备标签" /> -->
					<a-select
						mode="tags"
						size="default"
						v-model="query.tags"
						placeholder="请选择设备标签"
						style="width: 100%"
						@change="device_tag_handleChange">
						<a-select-option
							v-for="i in device_tag"
							:key="i.id.toString()">
							{{ i.tag }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="设备归属">
					<!-- <a-input v-model="query.country" placeholder="请选择设备国家" /> -->

					<a-select
						size="default"
						v-model="query.area"
						placeholder="请选择或搜索设备国家"
						style="width: 100%"
						@change="device_area_handleChange">
						<a-select-option v-for="i in device_area" :key="i">
							{{ i }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="网络类型">
					<!-- <a-radio-group v-model="query.no_tag">
            <a-radio value="all">全部</a-radio>
            <a-radio value="0">无标签</a-radio>
            <a-radio value="1">有标签</a-radio>
          </a-radio-group> -->

					<a-select
						size="default"
						v-model="query.net_type"
						placeholder="请选择网络类型"
						style="width: 100%"
						@change="device_net_handleChange">
						<a-select-option v-for="i in device_net" :key="i">
							{{ i }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="自动续费">
					<a-radio-group v-model="query.renew_status">
						<a-radio value="all">全部</a-radio>
						<a-radio value="0">未开启</a-radio>
						<a-radio value="1">开启</a-radio>
					</a-radio-group>
				</a-form-model-item>

				<!-- <a-form-model-item label="远程状态">
          <a-radio-group v-model="query.device_remote">
            <a-radio value="all">全部</a-radio>
            <a-radio value="1">可远程</a-radio>
            <a-radio value="0">不可远程</a-radio>
          </a-radio-group>
        </a-form-model-item> -->
			</a-form-model>
			<div class="btnNew">
				<a-button
					class="drawer_btn"
					type="primary"
					@click="reset_search">
					重置
				</a-button>
				<a-button type="primary" @click="onSearch_btn">
					确定筛选
				</a-button>
			</div>
		</a-modal>

		<device_detail
			v-if="detail_modalstatus"
			:detail_modalstatus="detail_modalstatus"
			:detaildata="check_device"
			:isbyun="tupecss"
			@cancel="cancel_detailmodal">
		</device_detail>
		<!-- 编辑自有设备 -->
		<AddDevice
			v-if="isShowEditDevice"
			:deviceInfo="deviceInfo"
			@cancel="() => (isShowEditDevice = false)"
			@fetchList="
				() => {
					fetchList(2);
				}
			" />
		<!-- <a-modal
      title="编辑自有设备"
      :visible="youyoudup"
      @ok="bianjisehb"
      @cancel="bianquxiao"
    >
      <div style="margin-top: 20px">
        <div style="line-height: 32px" class="timinha ant-form-item-required">
          设备名称
        </div>
        <a-input
          style="width: 440px; height: 36px"
          placeholder="请输入设备名称，便于识别"
          v-model="chec_nameup"
        />
      </div>
      <div style="margin-top: 20px">
        <div style="line-height: 32px" class="timinha ant-form-item-required">
          代理类型
        </div>

        <a-select
          size="default"
          v-model="chec_typeup"
          placeholder="请选择网络类型"
          class="seclok"
          style="width: 440px; height: 36px"
        >
          <a-select-option v-for="i in addhttplist" :key="i">
            {{ i }}
          </a-select-option>
        </a-select>
      </div>
      <div style="margin-top: 20px">
        <div style="line-height: 32px" class="timinha ant-form-item-required">
          设备地址或域名
        </div>
        <a-input
          style="width: 335px; height: 36px"
          placeholder="设备地址或域名"
          v-model="chec_ipup"
        />
        :
        <a-input
          style="width: 84px; height: 36px"
          placeholder="端口号"
          v-model="chec_portup"
        />
      </div>
      <div style="margin-top: 20px">
        <div style="line-height: 32px" class="timinha">登录账号</div>
        <a-input
          style="width: 440px; height: 36px"
          placeholder="代理登录账号"
          v-model="chec_accountup"
        />
      </div>
      <div style="margin-top: 20px">
        <div style="line-height: 32px" class="timinha">登录密码</div>
        <a-input
          style="width: 440px; height: 36px"
          placeholder="代理登录密码"
          v-model="chec_passwordup"
        />
      </div>
    </a-modal> -->

		<!-- 平台设备 -->
		<a-modal
			title="编辑设备名称"
			:visible="pintaiup"
			@ok="poinjia"
			@cancel="poinqu">
			<div style="display: flex">
				<div style="width: 60px; line-height: 32px; flex: none">
					新名称:
				</div>
				<a-input
					style="width: 300px"
					placeholder="输入新名称"
					v-model="checkdevice_newname"
					max="32" />
			</div>
		</a-modal>
		<!-- 添加自有设备 单独导入 -->
		<AddDevice
			v-if="editname_ziyou"
			@cancel="
				() => {
					editname_ziyou = false;
				}
			"
			@fetchList="
				() => {
					fetchList(2);
				}
			" />
		<!-- 批量导入自有 -->
		<multi_add_device
			v-if="multiAddVisible"
			:multiAddVisible.sync="multiAddVisible"
			@fetchList="fetchList(tupecss)"></multi_add_device>
		<a-modal
			title="批量修改设备名称"
			:visible="newEditName"
			@ok="editname_handleOk2"
			@cancel="editname_handleCancel2">
			<div style="display: flex">
				<div style="width: 60px; line-height: 32px; flex: none">
					名称:
				</div>
				<a-input
					style="width: 300px"
					placeholder="请输入名称"
					v-model="newEditNameInput" />
			</div>
		</a-modal>

		<device_unbind
			v-if="unbinddevice_modalstatus"
			:modalstatus="unbinddevice_modalstatus"
			:modaldata="check_device"
			@cancel="cancel_unbinddevice"
			@success="success_unbinddevice">
		</device_unbind>

		<device_bind
			v-if="binddevice_modalstatus"
			:modalstatus="binddevice_modalstatus"
			:modaldata="check_device"
			@cancel="cancel_binddevice"
			@success="success_binddevice">
		</device_bind>

		<edit_devicetag
			v-if="edit_devicetag_modalstatus"
			:modalstatus="edit_devicetag_modalstatus"
			:modaldata="check_device"
			@cancel="cancel_edit_devicetag"
			@success="success_edit_devicetag">
		</edit_devicetag>

		<tag_device
			v-if="show_tagmanage"
			@cancel="cancel_tagmanage"
			:isshow="show_tagmanage" />

		<a-modal
			title="批量新增标签"
			:visible="addVisible"
			@ok="add_handleOk"
			@cancel="add_handleCancel">
			<a-select
				mode="multiple"
				style="width: 380px"
				v-model="tagArr"
				placeholder="请选择标签">
				<a-select-option
					v-for="item in tag_data_list"
					:key="item.id"
					:value="item.id">
					{{ item.tag }}
				</a-select-option>
			</a-select>
		</a-modal>
		<a-modal
			title="批量移除标签"
			:visible="delVisible"
			@ok="del_handleOk"
			@cancel="del_handleCancel">
			<a-select
				mode="multiple"
				style="width: 380px"
				v-model="tagArr"
				placeholder="请选择标签">
				<a-select-option
					v-for="item in tag_data_list"
					:key="item.id"
					:value="item.id">
					{{ item.tag }}
				</a-select-option>
			</a-select>
		</a-modal>
	</div>
</template>
<script>
import noEquipment from "./noEquipment.vue";
import TagList from "./tagList.vue";
import {
	getList,
	getListChoose,
	device_cancelrenewstatus,
	device_updatedevicename,
	device_deletedevicemore,
	recovery_device_more,
	device_cancelrenewstatus_mora,
	device_devicetaglist,
	device_dcemore,
	addowndevic,
	updatendevic,
	getDeviceInfo,
	checkDeviceStatus,
} from "@/api/equipment";
import { BatchTag2 } from "@/api/tag";
import device_detail from "./compoents/device_detail.vue";
import device_unbind from "./compoents/device_unbind.vue";
import device_bind from "./compoents/device_bind.vue";
import tag_device from "./compoents/tag_device.vue";
import edit_devicetag from "./compoents/edit_devicetag.vue";
import multi_add_device from "./compoents/multi_add_device.vue";
import AddDevice from "./compoents/add_device.vue";

const columns = [
	{
		title: "设备名称",
		dataIndex: "device_name",
	},
	{
		title: "设备信息",
		dataIndex: "device_ip",
	},
	{
		title: "设备标签",
		dataIndex: "tags",
		scopedSlots: { customRender: "cell_tags" },
	},
	{
		title: "绑定环境",
		dataIndex: "env_name",
		scopedSlots: { customRender: "cell_name" },
	},
	{
		title: "设备归属",
		dataIndex: "device_area_title",
		scopedSlots: { customRender: "cell_under" },
		show: true,
	},
	{
		title: "套餐",
		dataIndex: "product",
	},
	{
		title: "网络类型",
		dataIndex: "net_type",
		scopedSlots: { customRender: "net_type" },
	},
	{
		title: "自动续费状态",
		dataIndex: "renew_status",
		scopedSlots: { customRender: "cell_renew" },
		show: true,
	},
	{
		title: "设备状态",
		width: 150,
		dataIndex: "status",
		scopedSlots: { customRender: "cell_status" },
		show: true,
	},
	{
		title: "设备到期时间",
		dataIndex: "expired_at",
	},
	{
		title: "操作",
		width: 220,
		fixed: "right",
		dataIndex: "operation",
		scopedSlots: { customRender: "operation" },
	},
];
const coluop = [
	{
		title: "设备名称",
		dataIndex: "device_name",
	},
	{
		title: "设备信息",
		dataIndex: "user_ip",
	},
	{
		title: "设备标签",
		dataIndex: "tags",
		scopedSlots: { customRender: "cell_tags" },
	},
	{
		title: "绑定环境",
		dataIndex: "env_name",
		scopedSlots: { customRender: "cell_name" },
	},
	{
		title: "账户名称",
		dataIndex: "account",
		show: true,
	},
	{
		title: "网络类型",
		dataIndex: "net_type",
		scopedSlots: { customRender: "net_type" },
	},
	{
		title: "操作",
		width: 220,
		fixed: "right",
		dataIndex: "operation",
		scopedSlots: { customRender: "operation" },
	},
];
const base_query = {
	keyword: null, //关键词
	device_name: null, //设备名称
	env_name: null, //环境名称
	device_ip: null, //设备IP
	country: null, //设备国家
	tags: null, //标签
	status: null, //状态 全部 all 0 正常 1 过期 2 待分配 3 故障 4 已删除(设备回收站)
	expire: null, //过期 全部 all 1 已过期 2 将要过期
	renew_status: null, //是否自动续费 全部 all 0 未开启 1 开启
	device_remote: null, //是否可远程 全部 all 0 不可远程 1 可远程
	no_tag: null, //有无标签设备 全部 all 0 无标签 1 有标签
	bind_env: null, //绑定环境 全部 all 0 已绑定环境 1 未绑定
	pagesize: 20,
	page: null,
};
const query = {
	keyword: null, //关键词
	device_name: null, //设备名称
	env_name: null, //环境名称
	device_ip: null, //设备IP
	country: null, //设备国家
	tags: undefined, //标签
	status: null, //状态 全部 all 0 正常 1 过期 2 待分配 3 故障 4 已删除(设备回收站)
	expire: null, //过期 全部 all 1 已过期 2 将要过期
	renew_status: null, //是否自动续费 全部 all 0 未开启 1 开启
	device_remote: null, //是否可远程 全部 all 0 不可远程 1 可远程
	no_tag: null, //有无标签设备 全部 all 0 无标签 1 有标签
	bind_env: null, //绑定环境 全部 all 0 已绑定环境 1 未绑定
	pagesize: 20,
	page: null,

	area: undefined,
	net_type: undefined,
};
export default {
	components: {
		noEquipment,
		TagList,
		device_detail,
		device_unbind,
		device_bind,
		tag_device,
		edit_devicetag,
		multi_add_device,
		AddDevice,
	},
	name: "equipment",
	data() {
		return {
			pinnum: 0,
			zunum: 0,
			pintaiup: false,
			youyoudup: false,
			formLayout: "horizontal",
			form: this.$form.createForm(this, { name: "coordinated" }),
			// 编辑设备开始
			isShowEditDevice: false,
			chec_nameup: "",
			chec_typeup: undefined,
			chec_ipup: "",
			chec_portup: "",
			chec_accountup: "",
			chec_passwordup: "",
			// 编辑设备结束
			current: "1", //选中的目录
			show_tagmanage: false, //标签管理状态

			drawer_visible: false,
			list: [],
			columns: columns,
			selectedRowKeys: [], // Check here to configure the default column
			query: query,
			base_query: base_query,
			common: {
				version: "1.0.0",
				mask: "dev",
				platform: 1,
			},
			labelCol: { span: 4 },
			listleng: 0,
			list2len: 0,
			confirmLoading: false,
			wrapperCol: { span: 24 },
			pagination: {
				pageNum: 1, //当前页数
				pageSize: 20, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
			loading: false,
			about_expire: 0,
			expired: 0,
			no_bind_env: 0,

			has_device: true,
			check_device: null, //选中的设备
			detail_modalstatus: false, //设备详情弹窗

			editname_state: false, //编辑设备名称弹窗
			editname_ziyou: false, //添加自有设备名称弹窗
			multiAddVisible: false, //批量导入自有设备弹窗
			checkdevice_newname: "", //选中设备新名称
			isjja: true,
			// 批量修改名称
			newEditName: false,
			newEditNameInput: "",

			unbinddevice_modalstatus: false, //解绑环境弹窗
			binddevice_modalstatus: false, //绑定环境弹窗
			edit_devicetag_modalstatus: false, //编辑绑定设备
			visible: false,
			device_area: [],
			tupecss: 1,
			device_net: [],
			addhttplist: ["http", "https", "socks5"],
			device_tag: [],
			listValue: [
				"设备名称",
				"设备信息",
				"设备标签",
				"绑定环境",
				"设备归属",
				"套餐",
				"网络类型",
				"自动续费状态",
				"设备状态",
				"设备到期时间",
			],
			listF: [
				{
					name: "设备名称",
				},
				{
					name: "设备信息",
				},
				{
					name: "设备归属",
				},
				// {
				//   name: "设备机型"
				// },
				{
					name: "远程状态",
				},
				{
					name: "绑定环境",
				},
				{
					name: "自动续费状态",
				},
				{
					name: "设备标签",
				},
				{
					name: "网络类型",
				},
				{
					name: "套餐",
				},
				{
					name: "设备状态",
				},
				// {
				//   name: "授权远程人员"
				// },
				{
					name: "设备到期时间",
				},
				// {
				//   name: "最后登录情况"
				// },
			],
			tag_data_list: [],

			addVisible: false,
			delVisible: false,
			tagArr: [],
			//测试代理字段
			websocketObj: null,
			websocket_port: 42536,
		};
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
	watch: {
		//监听websocket返回的ip检测信息，如果成功则发送添加自有设备的请求
	},
	async created() {
		//测试代理
	},
	async mounted() {
		this.getListChooseData();
		this.fetchList(1);
		this.get_initdata();
		let columns = JSON.parse(localStorage.getItem("columns"));
		if (columns && columns.length != 0) {
			this.columns = columns;
			let arra = [];
			this.columns.forEach((item, index) => {
				arra.push(item.title);
			});
			this.listValue = arra;
		}
	},
	methods: {
		handleMenuClick(e) {
			if (e.key == "single") {
				this.editname_ziyou = true;
			} else {
				this.multiAddVisible = true;
			}
		},
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
				}
			});
		},
		handleSelectChange(value) {
			this.form.setFieldsValue({
				note: `Hi, ${value === "male" ? "man" : "lady"}!`,
			});
		},
		async handleOk(e) {
			this.confirmLoading = true;
			let arr = this.selectedRowKeys.toString();
			await this.go_deletdevice(arr);
			this.fetchList(this.tupecss);
			this.visible = false;
			this.confirmLoading = false;
			this.selectedRowKeys = [];
		},
		handleCancel(e) {
			this.visible = false;
		},
		async get_initdata() {
			let { data } = await device_devicetaglist({});
			if (data.code == 200) {
				this.tag_data_list = data.data.list;
			}
		},
		// 续费
		renewal(record) {
			this.$router.push({
				name: "manage_renewal",
				query: {
					id: record.id,
				},
			});
		},
		// 批量添加标签
		addMoreTag() {
			if (!this.selectedRowKeys.length) {
				this.$message.error("请至少选择一条数据");
				return;
			}
			this.tagArr = [];
			this.addVisible = true;
		},
		async add_handleOk() {
			let arr = this.selectedRowKeys.toString();
			await BatchTag2("add", {
				id: arr,
				tag: this.tagArr.toString(),
			});
			this.addVisible = false;
			this.fetchList(this.tupecss);
		},
		add_handleCancel() {
			this.addVisible = false;
		},
		// 批量删除
		delMoreTag() {
			if (!this.selectedRowKeys.length) {
				this.$message.error("请至少选择一条数据");
				return;
			}
			this.tagArr = [];
			this.delVisible = true;
		},
		async del_handleOk() {
			let arr = this.selectedRowKeys.toString();
			await BatchTag2("del", {
				id: arr,
				tag_id: this.tagArr.toString(),
			});
			this.delVisible = false;
			this.fetchList(this.tupecss);
		},
		del_handleCancel() {
			this.delVisible = false;
		},
		// 清空标签
		emptyMoreTag() {
			if (!this.selectedRowKeys.length) {
				this.$message.error("请至少选择一条数据");
				return;
			}
			let arr = this.selectedRowKeys.toString();
			this.$confirm({
				title: "清空标签",
				content: "确定清空标签吗,您的操作不可逆!",
				onOk: async () => {
					await BatchTag2("empty", {
						id: arr,
					});
					this.fetchList(this.tupecss);
				},
				onCancel() {},
			});
		},
		editorName() {
			if (!this.selectedRowKeys.length) {
				this.$message.error("请至少选择一条数据");
				return;
			}
			this.newEditName = true;
		},
		editname_handleCancel2() {
			this.newEditNameInput = "";
			this.newEditName = false;
		},
		poinqu() {
			this.pintaiup = false;
		},
		poinjia() {
			if (!this.checkdevice_newname) {
				this.$message.error("请输入新的名称");
				return;
			}
			device_updatedevicename({
				device_id: this.check_device.id,
				device_name: this.checkdevice_newname,
			});
			this.pintaiup = false;
			this.fetchList(this.tupecss);
		},
		async editname_handleOk2() {
			if (!this.newEditNameInput) {
				this.$message.error("请输入新的名称");
				return;
			}
			let arr = this.selectedRowKeys.toString();
			await device_updatedevicename({
				device_id: arr,
				device_name: this.newEditNameInput,
			});
			this.newEditName = false;
			this.fetchList(this.tupecss);
		},
		// 批量自动续费
		async autoRenewal() {
			if (!this.selectedRowKeys.length) {
				this.$message.error("请至少选择一条数据");
				return;
			}
			let arr = this.selectedRowKeys.toString();
			await device_cancelrenewstatus_mora({
				device_id: arr,
				renew_status: true,
			});
			this.fetchList(this.tupecss);
		},
		async delDelete() {
			if (!this.selectedRowKeys.length) {
				this.$message.error("请至少选择一条数据");
				return;
			}
			this.visible = true;
		},
		async getListChooseData() {
			let { data } = await getListChoose({});
			// 设备归属
			this.device_area = data.data.device_area;
			// 设备网络类型
			this.device_net = data.data.device_net;
			// 设备标签
			this.device_tag = data.data.device_tag;
		},
		//恢复设备
		async recover_device(record) {
			let { data } = await recovery_device_more({
				device_id: record.id,
			});
			if (data.code == 200) {
				this.fetchList(this.tupecss);
				this.$message.success("操作成功");
			}
		},
		//取消标签管理
		cancel_tagmanage() {
			this.show_tagmanage = false;
		},
		device_area_handleChange() {},
		device_net_handleChange() {},
		device_tag_handleChange() {},
		//编辑名称弹窗
		show_editname_pop(record) {
			if (this.tupecss == 1) {
				// 平台
				this.check_device = record;
				this.checkdevice_newname = record.device_name;
				this.pintaiup = true;
			} else if (this.tupecss == 2) {
				// 自有
				getDeviceInfo({ id: record.id }).then(res => {
					const data = res.data;
					if (data.code == 200) {
						this.deviceInfo = data.data;
						this.isShowEditDevice = true;
					}
				});
				this.check_device = record;

				this.chec_nameup = record.device_name;
				this.chec_typeup = record.net_type;
				this.chec_ipup = record.device_ip;
				this.chec_portup = record.ip_port;
				this.chec_accountup = record.account;
				this.chec_passwordup = record.password;

				// device_name: this.chec_nameup,
				// net_type: this.chec_typeup,
				// device_ip: this.chec_ipup,
				// ip_port: this.chec_portup,
				// account: this.chec_accountup,
				// password: this.chec_passwordup,

				// this.checkdevice_newname = record.device_name;

				this.youyoudup = true;
			}
		},
		//添加自有设备弹窗
		show_editname_ziyou(record) {
			this.editname_ziyou = true;
		},
		editname_handleCancel() {
			this.editname_state = false;
		},
		bianquxiao() {
			this.youyoudup = false;
		},
		//编辑设备名称
		bianjisehb() {
			if (!this.chec_nameup) {
				this.$message.error("请输入设备名称！");
				return;
			}
			if (!this.chec_typeup) {
				this.$message.error("请选择网络类型!");
				return;
			}
			if (!this.chec_ipup) {
				this.$message.error("请输入设备地址或域名!");
				return;
			}
			if (!this.chec_portup) {
				this.$message.error("请输入端口号!");
				return;
			}
			// if (!this.chec_accountup) {
			//   this.$message.error("请输入代理登录账号!");
			//   return;
			// }
			// if (!this.chec_passwordup) {
			//   this.$message.error("请输入代理登录密码!");
			//   return;
			// }
			//  chec_nameup: "",
			// chec_typeup: undefined,
			// chec_ipup: "",
			// chec_portup: "",
			// chec_accountup: "",
			// chec_passwordup: "",

			updatendevic({
				id: this.check_device.id,
				device_name: this.chec_nameup,
				net_type: this.chec_typeup,
				device_ip: this.chec_ipup,
				ip_port: this.chec_portup,
				account: this.chec_accountup,
				password: this.chec_passwordup,
			}).then(data => {
				if (data.data.code == 200) {
					this.$message.success("编辑成功");
					this.youyoudup = false;
					this.fetchList(2);
				}
			});
		},

		//解绑环境弹窗
		open_unbinddevice_pop(record) {
			this.check_device = record;
			this.unbinddevice_modalstatus = true;
		},
		cancel_unbinddevice() {
			this.unbinddevice_modalstatus = false;
			this.fetchList(this.tupecss);
		},
		success_unbinddevice() {
			this.unbinddevice_modalstatus = false;
			this.fetchList(this.tupecss);
		},

		//绑定环境弹窗
		open_binddevice_pop(record) {
			if (record.status == 2 || record.status == 5) {
				this.$message.warning("请等待设备分配");
				return;
			}
			if (record.status == 1) {
				this.$message.warning("当前设备已过期");
				return;
			}
			this.check_device = record;
			this.binddevice_modalstatus = true;
		},
		cancel_binddevice() {
			this.binddevice_modalstatus = false;
			this.fetchList(this.tupecss);
		},
		success_binddevice() {
			this.binddevice_modalstatus = false;
			this.fetchList(this.tupecss);
		},

		//编辑绑定设备弹窗
		open_edit_devicetag_pop(record) {
			this.check_device = record;
			this.edit_devicetag_modalstatus = true;
		},
		cancel_edit_devicetag() {
			this.edit_devicetag_modalstatus = false;
			this.fetchList(this.tupecss);
		},
		success_edit_devicetag() {
			this.edit_devicetag_modalstatus = false;
			this.fetchList(this.tupecss);
		},

		//自动续费弹窗
		change_autopay(record, type) {
			let that = this;

			let c_1 = "";
			let c_2 = "";
			if (type == 1) {
				c_1 = "开启自动续费";
				c_2 = "确定将已选择设备开启按月自动续费？";
			}
			if (type == 0) {
				c_1 = "关闭自动续费";
				c_2 = "确定将已选择设备关闭按月自动续费？";
			}

			this.$confirm({
				title: c_1,
				content: "设备名称:" + record.device_name + ", " + c_2,
				onOk() {
					that.go_autopay(record.id, type);
					return false;
				},
				onCancel() {},
			});
		},
		//自动续费
		async go_autopay(id, type) {
			let { data } = await device_cancelrenewstatus({
				device_id: id,
				renew_status: type,
			});
			if (data.code == 200) {
				this.fetchList(this.tupecss);
				this.$message.success("操作成功");
			}
		},
		//删除设备弹窗
		change_deletdevice_pop(record) {
			let that = this;

			this.$confirm({
				title: "删除设备",
				content:
					"确定删除设备 " +
					record.device_name +
					" 吗？提示：若删除的是未过期设备，在设备过期前可在回收站找回。",
				onOk() {
					that.go_deletnoe(record.id);
					return false;
				},
				onCancel() {},
			});
		},
		//自动删除设备
		async go_deletnoe(id) {
			let { data } = await device_dcemore({
				device_id: id,
			});
			if (data.code == 200) {
				this.fetchList(this.tupecss);
				this.$message.success("操作成功");
			}
		},
		//自动删除设备
		async go_deletdevice(id) {
			let { data } = await device_deletedevicemore({
				device_id: id,
			});
			if (data.code == 200) {
				this.fetchList(this.tupecss);
				this.$message.success("操作成功");
			}
		},

		//格式化环境名称
		format_name(data) {
			// if (!data.env_name) {
			//   return [].toString()
			// }
			// let c_1 = []
			// data.env_name.forEach(item => {
			//   c_1.push(item.env_name)
			// })
			// return c_1.toString()
			return data.env_name;
		},
		//格式化远程
		formate_remote(data) {
			if (data == 0) {
				return "不可远程";
			}
			if (data == 1) {
				return "可远程";
			}
		},
		formate_tags(data) {
			if (!data) {
				return [].toString();
			}
			return data.toString();
		},
		//格式化自动续费
		formate_renew(data) {
			if (data == 0) {
				return "未开启";
			}
			if (data == 1) {
				return "开启";
			}
		},
		//格式化状态
		formate_status(data) {
			if (data == 0) {
				return "正常";
			}
			if (data == 1) {
				return "过期";
			}
			if (data == 2) {
				return "待分配";
				// return "";
			}
			if (data == 3) {
				return "故障";
			}
			if (data == 4) {
				return "已删除";
			}
			if (data == 5) {
				return "分配中";
			}
		},

		handleClick: function ({ key }) {
			this.reset_search();
			this.current = key;
			this.query = JSON.parse(JSON.stringify(this.base_query));

			if (key === "2") {
				this.query = { ...query, expire: 2 };
			}
			if (key === "3") {
				this.query = { ...query, expire: 1 };
			}
			if (key === "4") {
				this.query = { ...query, status: 4 };
			}
			if (key === "5") {
				this.query = { ...query, bind_env: 1 };
			}
			if (key === "6") {
				this.query = { ...query, renew_status: 1 };
			}

			this.pagination.pageNum = 1;
			this.fetchList(this.tupecss);
		},
		// 设备类别改变
		changeEqType: function () {},

		async onSearch_btn() {
			this.fetchList(this.tupecss);
			this.drawer_visible = false;
		},
		reset_search() {
			this.query = {
				keyword: null, //关键词
				device_name: null, //设备名称
				env_name: null, //环境名称
				device_ip: null, //设备IP
				country: null, //设备国家
				tags: undefined, //标签
				status: null, //状态 全部 all 0 正常 1 过期 2 待分配 3 故障 4 已删除(设备回收站)
				expire: null, //过期 全部 all 1 已过期 2 将要过期
				renew_status: null, //是否自动续费 全部 all 0 未开启 1 开启
				device_remote: null, //是否可远程 全部 all 0 不可远程 1 可远程
				no_tag: null, //有无标签设备 全部 all 0 无标签 1 有标签
				bind_env: null, //绑定环境 全部 all 0 已绑定环境 1 未绑定
				pagesize: 20,
				page: null,

				area: undefined,
				net_type: undefined,
			};
		},
		afterVisibleChange(val) {},
		showDrawer() {
			this.drawer_visible = true;
		},
		onClose() {
			this.drawer_visible = false;
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},
		onRenew: function () {},
		//查看设备待分配进度
		checkDevice(record) {
			this.loading = true;
			checkDeviceStatus({ id: record.id })
				.then(res => this.$message.info("设备状态还在开通中，请等待"))
				.catch(err => {
					this.$message.info("设备状态还在开通中，请等待");
				})
				.finally(() => {
					this.fetchList(this.tupecss);
				});
		},
		//显示详情
		show_detail(record) {
			this.check_device = record;
			this.detail_modalstatus = true;
		},
		cancel_detailmodal() {
			this.detail_modalstatus = false;
			this.fetchList(this.tupecss);
		},

		async fetchList(val) {
			this.tupecss = val ? val : 1;

			if (this.tupecss == 2) {
				this.columns = coluop;
			} else {
				this.columns = columns;
			}
			this.loading = true;
			const { data } = await getList({
				...this.query,
				pagesize: 20,
				page: this.pagination.pageNum,
				type: val ? val : 1,
			});

			if (data.code == 200) {
				if (this.tupecss == 1) {
					this.pinnum = data.data.total;
				}
				if (this.tupecss == 2) {
					this.zunum = data.data.total;
				}
				this.pagination.total = data.data.total;
				this.loading = false;

				this.list = data.data.list;

				if (this.list.length == 0) {
					this.has_device = false;
				} else {
					this.has_device = true;
				}

				if (this.tupecss == 1) {
					this.about_expire = data.data.about_expire;
				} else if (this.tupecss == 2) {
					this.about_expire = data.data.expired;
				}

				this.expired = data.data.expired;
				this.no_bind_env = data.data.no_bind_env;
			}
			if (this.tupecss == 1) {
				const { data } = await getList({
					...this.query,
					pagesize: 20,
					page: this.pagination.pageNum,
					type: 2,
				});

				if (data.code == 200) {
					this.zunum = data.data.total;
				}
			}
		},
		handleTableChange(pagination) {
			this.pagination.pageNum = pagination.current;

			this.fetchList(this.tupecss);
		},
		onSearchKey: function (key) {
			this.query.keyword = key;
			this.fetchList(this.tupecss);
		},
		// 购买设备
		buyEq: function () {
			this.$router.push("/manage/buyequipment");
		},
		// 续费设备
		renewalEq: function () {},
		fieldChange() {
			let arr = [];
			this.listValue.map(item => {
				if (item === "设备名称") {
					arr.push({
						title: "设备名称",
						dataIndex: "device_name",
					});
				}

				if (item === "设备信息") {
					arr.push({
						title: "设备信息",
						dataIndex: "device_ip",
					});
				}

				if (item === "设备归属") {
					arr.push({
						title: "设备归属",
						dataIndex: "device_area_title",
						scopedSlots: { customRender: "cell_under" },
						show: true,
					});
				}

				if (item === "远程状态") {
					arr.push({
						title: "远程状态",
						dataIndex: "device_remote",
						scopedSlots: { customRender: "cell_device_remote" },
						show: true,
					});
				}

				if (item === "绑定环境") {
					arr.push({
						title: "绑定环境",
						dataIndex: "env_name",
						scopedSlots: { customRender: "cell_name" },
					});
				}

				if (item === "自动续费状态") {
					arr.push({
						title: "自动续费状态",
						dataIndex: "renew_status",
						scopedSlots: { customRender: "cell_renew_status" },
					});
				}

				if (item === "设备标签") {
					arr.push({
						title: "标签",
						dataIndex: "tags",
						scopedSlots: { customRender: "cell_tags" },
					});
				}

				if (item === "网络类型") {
					arr.push({
						title: "网络类型",
						dataIndex: "net_type",
						scopedSlots: { customRender: "net_type" },
					});
				}

				if (item === "网络属性") {
					arr.push({
						title: "网络属性",
						dataIndex: "device_net",
						scopedSlots: { customRender: "device_net" },
					});
				}

				if (item === "设备状态") {
					arr.push({
						title: "设备状态",
						dataIndex: "status",
						scopedSlots: { customRender: "cell_status" },
						show: true,
					});
				}

				// if (item === '授权远程人员') {
				//   arr.push({
				//     title: "授权远程人员",
				//     dataIndex: "member_id",
				//   })
				// }

				if (item === "设备到期时间") {
					arr.push({
						title: "设备到期时间",
						dataIndex: "expired_at",
					});
				}

				// if (item === '最后登录情况') {
				//   arr.push({
				//     title: "最后登录情况",
				//     dataIndex: "member_id",
				//   })
				// }
			});
			arr.push({
				title: "操作",
				width: 250,
				fixed: "right",
				dataIndex: "operation",
				scopedSlots: { customRender: "operation" },
			});
			this.columns = arr;
			localStorage.setItem("columns", JSON.stringify(arr));
			//
		},
	},
};
</script>

<style scoped lang="less">
.zhonjian1 {
	.shebiea {
		padding: 8px 15px;
		border: 1px solid #d9d9d9;
		border-left: none;
		cursor: pointer;
		&.lanse {
			border: 1px solid #4c84ff;
			color: #4c84ff;
		}
	}
}
/deep/ .ant-modal-header {
	border: 0;
}

/deep/ .ant-modal-footer {
	border: 0;
}

::v-deep {
	.ant-modal {
		.ant-modal-body {
			padding-top: 0 !important;
		}
	}
}

.table-popover {
	.popover-content {
		//width: 90px;
		padding-top: 8px;
		cursor: pointer;
	}

	.popover-content:hover {
		color: #4c84ff;
	}

	.popover_edit-content {
		width: 110px;
		padding-top: 5px;
		//text-align: center;
		height: 40px;
		line-height: 40px;
		cursor: pointer;
		padding: 0 10px;

		&:hover {
			background: #f5f5f5;
		}
	}

	.popover_edit-content:hover {
		color: #4c84ff;
	}
}
.timinha {
	height: 24px;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2c354b;
	line-height: 24px;
	margin-bottom: 8px;
}
.equipment {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	margin-left: 15px;
	margin-right: 15px;

	.menu {
		width: 210px;
		background-color: #fff;
		padding-top: 38px;

		.menu_one {
			display: flex;
			flex-direction: row;
			height: 46px;

			.ant-menu-item-selected {
				background: linear-gradient(90deg, #f7f9ff 0%, #ebf0fc 100%);
			}

			.my_equipment {
				margin-top: 14px;
				margin-right: 7px;
				width: 18px;
				height: 16px;
				background-image: url("../../../assets/img/equipment/device@2x.png");
				background-repeat: no-repeat;
			}

			.will_expire {
				background-image: url("../../../assets/img/equipment/will_expire@2x.png");
				background-size: 100%;
			}

			.expired {
				background-image: url("../../../assets/img/equipment/expired@2x.png");
				background-size: 100%;
			}

			.bin {
				background-image: url("../../../assets/img/equipment/bin@2x.png");
				background-size: 100%;
			}

			.bound {
				background-image: url("../../../assets/img/equipment/bound@2x.png");
				background-size: 100%;
			}

			.renewal {
				background-image: url("../../../assets/img/equipment/renewal@2x.png");
				background-size: 100%;
			}

			.title {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #374567;
				line-height: 46px;
			}

			.count {
				border: 1px solid #374567;
			}
		}
	}

	.content {
		flex: 1;
		margin-left: 10px;
		min-height: calc(100vh - 126px);
		background-color: #fff;

		.search_panel {
			margin-top: 25px;
			// margin-left: 36px;
			float: left;
			width: 100%;

			.eq_radio {
				min-width: 102px;
				float: left;
			}

			.eq_buy_btn {
				margin-left: 16px;
				float: left;
			}

			.fieldBox,
			.fieldBox2 {
				float: left;
				margin-left: 16px;
				width: 104px;
				position: relative;

				.eq_buy_btn {
					width: 104px;
					position: absolute;
					left: 0;
					top: 0;
					cursor: pointer;
					margin-left: 0;

					&.disabled {
						cursor: not-allowed;
						color: rgba(0, 0, 0, 0.25);
						background-color: #f5f5f5;
						border-color: #d9d9d9;
						text-shadow: none;
						box-shadow: none;
					}
				}

				.batch {
					height: 32px;
					width: 168px;
					opacity: 0;
					cursor: pointer;
					position: absolute;
					left: 0;
					top: 0;
				}

				.field {
					height: 32px;
					width: 168px;
					opacity: 0;
					cursor: pointer;
				}
			}

			.right {
				float: right;

				.eq_buy_btn {
					margin-right: 16px;
					float: left;
				}

				.fliter_drawer {
					// margin-right: 16px;
					float: left;
					border-radius: 0;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						width: 16px;
						height: 16px;
						background: url(../../../assets/img/filter-icon.png)
							no-repeat center center;
						background-size: 100% 100%;
						display: inline-block;
						margin-right: 4px;
					}
				}

				.eq_buy_btn {
					border-radius: 0;

					/deep/ input {
						border-radius: 0;
					}
				}
			}
		}

		.eq_info {
			float: left;
			width: 100%;
			margin-top: 14px;
			padding: 0 13px;

			.view_btn {
				float: left;
			}

			.mora {
				font-size: 0;
				width: 32px;
				height: 32px;
				background: url(../../../assets/img/mora-icon.png) no-repeat
					center center;
				background-size: 100% 100%;
			}
		}
	}
}

.ant-drawer-content-wrapper {
	width: 450px;
}

.drawer_btn {
	margin-right: 10px !important;
	background: #f5f5f5;
	border: none;
	font-size: 14px;
	font-weight: 400;
	color: #374567;
	line-height: 22px;
}

::v-deep {
	.eq_buy_btn {
		border-radius: 0;
	}

	.ant-select-dropdown {
		height: 500px;
	}
}
::v-deep .seclok .ant-select-selection--single {
	height: 36px;
}
::v-deep .seclok .ant-select-selection--single .ant-select-selection__rendered {
	line-height: 36px;
}
.btnNew {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #e9e9e9;
	padding: 12px 16px;
	background: #fff;
	text-align: right;
	z-index: 1;
	display: flex;
	justify-content: space-between;
}
/deep/ .tinazj .ant-modal-title#rcDialogTitle0 {
	width: 108px;
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2c354b;
	line-height: 25px;
}
.zhonjian1 {
	margin-top: 93px;
	margin-bottom: 15px;
	margin-left: 18px;
}
.yanzs {
	padding: 8px 15px;
	border: 1px solid #d9d9d9;
	border-right: none;
	cursor: pointer;
	&.lanse {
		color: #4c84ff;
		border: 1px solid #4c84ff;
	}
}

.wenzihuang {
	padding: 0 10px;
	height: 16px;
	border-radius: 8px;
	border: 1px solid #ffbe00;
	font-size: 12px !important;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffbe00 !important;
	line-height: 15px !important;
	margin-top: 15px;
	margin-left: 11px;
}
</style>
