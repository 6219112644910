<template>
	<a-modal
		title="批量导入"
		:width="600"
		:visible="multiAddVisible"
		:footer="null"
		@cancel="
			() => {
				$emit('fetchList');
				$emit('update:multiAddVisible', false);
			}
		">
		<div class="top_tip">
			<img src="@/assets/img/tips-icon.png" alt="" class="img" />
			<div class="tip_txt">
				一次性最多导入500台设备，导入过程中请保持网络连接。
			</div>
		</div>
		<div class="download_container">
			<p class="title">下载模板：根据模板提示完善内容</p>
			<a
				class="download_btn"
				download="批量导入设备模板.xlsx"
				href="/批量导入设备模板.xlsx"
				>下载模板(.xlsx)</a
			>
		</div>
		<div class="upload_container">
			<img
				v-show="!fileList.length"
				src="@/assets/img/file.png"
				alt=""
				class="img" />
			<a-upload
				name="file"
				:before-upload="beforeUpload"
				:remove="handleRemove"
				:multiple="false"
				:disabled="isShowLoading"
				:fileList="fileList">
				<a-button> <a-icon type="upload" />上传文件</a-button>
				<p class="upload_tip">仅支持上传格式为xls或xlsx的文件</p>
			</a-upload>
			<!-- 加载动画 -->
			<a-spin v-if="isShowLoading">
				<a-icon
					slot="indicator"
					type="loading"
					style="font-size: 24px"
					spin />
				<p>{{ loadingTxt }}</p>
			</a-spin>
			<a-progress
				style="padding: 10px"
				v-if="percent"
				:percent="percent" />
			<p class="result" v-if="isShowResult">
				共导入{{ deviceList.length }}条数据，成功<span
					style="color: #52c41a"
					><a-icon type="check" />{{ successCount }}</span
				>次，失败<span style="color: #ff4d4f"
					><a-icon type="warning" />{{
						deviceList.length - successCount
					}}</span
				>次,<span class="exportTxt" @click="exportResult">点击导出</span
				>结果
			</p>
		</div>
		<div class="footer">
			<a-button
				@click="showRead"
				:disabled="isShowLoading || !deviceList.length"
				>在线预览</a-button
			>
			<a-button
				@click="confirm"
				:disabled="isShowLoading || !deviceList.length || isShowResult"
				>{{ isShowResult ? "成功导入" : "确定导入" }}</a-button
			>
		</div>

		<!-- 在线预览弹窗 -->
		<a-modal
			title="上传结果预览"
			:width="1210"
			:bodyStyle="{ overflow: 'auto', maxHeight: '500px' }"
			:visible="readVisible"
			@cancel="readVisible = false"
			@ok="handleOk">
			<div class="table_header">
				<span class="header_item">设备名称</span>
				<span class="header_item">代理类型</span>
				<span class="header_item">设备地址</span>
				<span class="header_item">端口号</span>
				<span class="header_item">登录账号</span>
				<span class="header_item">登录密码</span>
			</div>
			<a-form-model
				v-for="(item, index) in deviceList"
				:key="index"
				:model="item"
				:ref="'form'"
				:rules="rules"
				:layout="'inline'">
				<a-form-model-item
					prop="device_name"
					:rules="[
						{
							required: true,
							message: '设备名称为必填项',
							trigger: 'blur',
						},
						{
							validator: (rule, value) => {
								return (
									deviceList.filter(
										i => i.device_name == value
									).length == 1
								);
							},
							message: '设备名称不能重复',
							trigger: 'blur',
						},
					]">
					<a-input v-model="item.device_name" />
				</a-form-model-item>
				<a-form-model-item
					prop="net_type"
					:rules="[
						{
							required: true,
							message: '代理类型为必填项',
							trigger: 'blur',
						},
						{
							validator: (rule, value) => {
								return ['http', 'https', 'socks5'].includes(
									value
								);
							},
							message: '代理类型必须为http、https或socks5',
							trigger: 'blur',
						},
					]">
					<a-input v-model="item.net_type" />
				</a-form-model-item>
				<a-form-model-item prop="device_ip">
					<a-input v-model="item.device_ip" />
				</a-form-model-item>
				<a-form-model-item prop="ip_port">
					<a-input v-model="item.ip_port" />
				</a-form-model-item>
				<a-form-model-item prop="account">
					<a-input v-model="item.account" />
				</a-form-model-item>
				<a-form-model-item prop="password">
					<a-input v-model="item.password" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</a-modal>
</template>
<script>
import { importExcel, exportExcel } from "@/utils/utils.js";
import * as XLSX from "xlsx/xlsx.mjs";
import { environment_create } from "@/api/environment.js";
import { addowndevic } from "@/api/equipment";
export default {
	props: ["multiAddVisible"],
	data() {
		//校验规则
		const rules = {
			device_ip: [
				{
					required: true,
					message: "设备地址为必填项",
					trigger: "blur",
				},
			],
			ip_port: [
				{
					required: true,
					message: "端口号为必填项",
					trigger: "blur",
				},
			],
		};
		return {
			//上传相关
			rules,
			fileList: [],
			deviceList: [],
			readVisible: false,
			percent: 0,
			successCount: 0,
			isShowResult: false,
			isShowLoading: false,
			loadingTxt: "正在批量检测设备，可能需要耗时一分钟，请勿关闭该页面",
			ipCheckCount: 0,
			total: 0,
			currentTotal: 0,
			timeID: "",
			//设备数据
			proxy_type: "", //["http", "https", "socks5"],
			proxy_ip: "",
			proxy_port: "",
			proxy_user_name: "",
			proxy_password: "",
		};
	},

	created() {},
	mounted() {},
	computed: {
		ipInfo() {
			return this.$store.state.ipInfo;
		},
	},
	watch: {
		multiAddVisible() {
			Object.assign(this.$data, this.$options.data()); //每次打开窗口时重置数据
		},
		deviceList(newVal, oldVal) {
			if (oldVal.length) {
				this.clearCheck();
				this.successCount = 0;
				this.isShowResult = false;
			}
		},
		percent(newVal) {
			if (newVal == 100) {
				const timer = setTimeout(() => {
					this.percent = 0;
					this.isShowResult = true;
					this.isShowLoading = false;
					clearTimeout(timer);
				}, 500);
			}
		},
		//监听websocket返回的ip检测信息，如果成功则发送添加自有设备的请求
		async ipInfo(newVal) {
			// if (newVal.is_success == "1") {
			//   let { data } = await addowndevic({
			//     device_name: this.chec_name,
			//     net_type: this.chec_type,
			//     device_ip: this.chec_ip,
			//     user_ip: JSON.parse(newVal.data).data.user_ip,
			//     ip_port: this.chec_port,
			//     account: this.chec_account,
			//     password: this.chec_password,
			//     // device_id: this.check_device.id,
			//     // device_name: this.checkdevice_newname,
			//   });
			//   if (data.code == 200) {
			//   }
			// } else {
			// }
			const item = this.deviceList.find(
				i => i.device_name == newVal.device_name.split(this.timeID)[0]
			);
			if (newVal.data) {
				item.user_ip = JSON.parse(newVal.data).data.user_ip;
			}

			item.is_success = newVal.is_success;
			if (item.is_success == "0") {
				item.result = "设备不可用";
				this.changeProcess();
			} else {
				addowndevic(item)
					.then(res => {
						res.data.code == 200 && this.successCount++;
						item.result = res.data.msg;
						this.changeProcess();
					})
					.catch(err => {
						item.result = err;
						this.changeProcess();
					});
			}
			this.ipCheckCount++;
		},
		ipCheckCount(newVal) {
			if (newVal == this.deviceList.length) {
				this.loadingTxt = "检测完成，正在批量上传";
				// const promiseArr = this.deviceList.map((item) =>
				//   item.is_success == "1" ? addowndevic(item) : 0
				// );
				// Promise.all(promiseArr).then((res) => {
				//   res.forEach((i) => {
				//     if (i.data) {
				//       i.data.code == 200 && this.successCount++;
				//       item.result = i.data.msg;
				//     }
				//   });
				//   this.isShowLoading = false;
				//   this.isShowResult = true;
				// });
			}
		},
		// percent(newVal) {
		//   if (newVal == 100) {
		//     const timer = setTimeout(() => {
		//       this.percent = 0;
		//       this.isShowResult = true;
		//       clearTimeout(timer);
		//     }, 500);
		//   }
		// },
	},
	beforeDestroy() {},
	methods: {
		testlog(a, b) {},
		//改变进度条
		changeProcess() {
			this.currentTotal++;
			this.percent = Math.floor((this.currentTotal / this.total) * 100);
			// if (this.currentTotal == this.total) {
			//   this.isShowLoading = false;
			//   this.isShowResult = true;
			// }
		},
		//字段校验函数
		checkNet_type(rule, value) {
			return ["http", "https", "socks5"].includes(value);
		},

		showRead() {
			this.readVisible = true;
		},
		//预览窗口确定事件
		async handleOk() {
			const checkStatus = await this.checkForm();
			checkStatus && (this.readVisible = false);
		},
		async checkForm() {
			const forms = this.$refs.form;
			const promiseArr = forms.map(form => form.validate());
			const res = await Promise.all(promiseArr).catch(err => {
				this.$message.error(
					"填写信息有误，请点击预览进行在线修改，或者修改excel文件后重新上传"
				);
				return err;
			});
			return res;
		},
		clearCheck() {
			const forms = this.$refs.form;
			forms.forEach(form => form.clearValidate());
		},
		//确定导入
		async confirm() {
			const checkStatus = await this.checkForm();
			if (checkStatus) {
				this.isShowLoading = true;
				this.total = this.deviceList.length;
				this.currentTotal = 0;
				this.get_client_params();
				// let total = this.deviceList.length;
				// let currentTotal = 0;
				// const promiseArr = this.deviceList.map((item) => {
				//   return environment_create({
				//     environment: Object.assign(this.c_1, item),
				//     config: this.c_2,
				//   })
				//     .then((res) => {
				//       currentTotal++;
				//       this.percent = Math.floor((currentTotal / total) * 100);
				//       res.data.code == 200 && this.successCount++;
				//     })
				//     .catch((err) => {
				//       currentTotal++;
				//       this.percent = Math.floor(currentTotal / total);
				//     });
				// });
				// Promise.all(promiseArr).then((res) => {
				// });
			}
		},
		//websocket
		get_client_params() {
			const token = localStorage.token;
			if (!token) {
				this.$message.error("请先登录");
				return;
			}
			this.timeID = token.slice(token.length - 4) + Date.now(); //生成时间戳作为每台的设备的唯一标识
			const list = this.deviceList.map(item => {
				return {
					device_name: item.device_name + this.timeID,
					proxy_type: item.net_type,
					proxy_ip: item.device_ip + "",
					proxy_port: item.ip_port + "",
					proxy_user_name: item.account ? item.account + "" : "",
					proxy_password: item.password ? item.password + "" : "",
				};
			});
			let c_1 = JSON.stringify({
				allData: list,
				message: "/connection/testProxy",
			});
			// const XORencryption = val => {
			// 	if (typeof val !== "string") return val;
			// 	val = this.$Base64.encode(val);
			// 	var m = "y";
			// 	let message = "";
			// 	for (var i = 0; i < val.length; i++) {
			// 		if (0 == i % 2) {
			// 			message += String.fromCharCode(val.charCodeAt(i) ^ m.charCodeAt(0));
			// 		} else {
			// 			message += val[i];
			// 		}
			// 	}
			// 	return this.$Base64.encode(message);
			// };
			let b_1 = this.$encrypt(c_1);
			this.$store.state.websocket.send(b_1);
		},
		//导出结果
		exportResult() {
			this.deviceList.unshift({
				device_name: "设备名称",
				net_type: "代理类型",
				device_ip: "设备地址",
				ip_port: "端口号",
				account: "登录账号",
				password: "登录密码",
				result: "导入结果",
			});
			const header = [
				"device_name",
				"net_type",
				"device_ip",
				"ip_port",
				"account",
				"password",
				"result",
			];
			exportExcel(XLSX, this.deviceList, header);
		},
		//上传文件
		beforeUpload(file) {
			if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
				// return alert("上传格式不正确，请上传xls或者xlsx格式");
				this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
				return false;
			}
			importExcel(file, XLSX, "device")
				.then(res => {
					if (res.length >= 500) {
						this.$message.error("一次性最多导入500条数据");
						return false;
					}
					res.forEach((item, index) => {
						item.account = item.account ? item.account : "";
						item.password = item.password ? item.password : "";
					});
					this.deviceList = res;
					this.readVisible = true;
					this.fileList = [file];
				})
				.catch(err => {
					this.$message.error(err);
				});
			return false;
		},
		//删除文件清除文件列表
		handleRemove() {
			this.fileList = [];
		},
	},
};
</script>
<style scoped lang="less">
@import "@/style/mixin.less";
.ant-form.ant-form-inline {
	white-space: nowrap;
}
.top_tip {
	padding: 8px 16px;
	background: #fff9f2;
	border-radius: 3px;
	border: 1px solid #ed7b2f;
	.flex();
	.img {
		width: 20px;
	}
	.tip_txt {
		width: 490px;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 20px;
	}
}
.download_container {
	margin: 20px 0 12px;
	padding-top: 20px;
	height: 102px;
	background: #f8f9fd;
	border-radius: 2px;
	border: 1px solid #dedfe2;
	text-align: center;
	.title {
		margin-bottom: 12px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2c354b;
		line-height: 20px;
	}
	.download_btn {
		display: block;
		margin: 0 auto;
		width: 123px;
		height: 34px;
		background: #ffffff;
		border-radius: 2px;
		border: 1px solid #dedfe2;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2c354b;
		line-height: 34px;
		text-align: center;
	}
}
.upload_container {
	height: 200px;
	background: #f8f9fd;
	border-radius: 2px;
	border: 1px solid #dedfe2;
	.flex(center;@direction:column);
	.result {
		.exportTxt {
			color: #4c84ff;
			cursor: pointer;
		}
	}
}
::v-deep .ant-upload.ant-upload-select {
	text-align: center;
}
.footer {
	margin-top: 20px;
	.flex(flex-end);
	.confirm_btn {
		width: 90px;
		height: 30px;
		background: #f4f4f4;
		border: 1px solid #dedfe2;
	}
}
.table_header {
	width: 1160px;
	.flex();
	.header_item {
		width: 180px;
		font-weight: 700;
	}
}
</style>
