import request from "@/utils/request";

export const department = params => {
	//  部门列表
	return request({
		url: "/client_v1/user/list-department",
		method: "POST",
		params,
	});
};
export const chosmember = data => {
	//  查询所有用户
	return request({
		url: "/client_v1/environment/choose-auth-member",
		method: "POST",
		data,
	});
};
// 国家列表
export const user_platform = data => {
	return request({
		url: "/client_v1/environment/platform-country",
		method: "POST",
		data,
	});
};
// 添加自定义平台
export const user_crepla = data => {
	return request({
		url: "/client_v1/environment/create-platform",
		method: "POST",
		data,
	});
};
// 删除自定义平台
export const delPlatfrom = data => {
	return request({
		url: "/client_v1/platform/del-custom-platform",
		method: "POST",
		data,
	});
};

export const getmember = data => {
	//  查询已授权的用户
	return request({
		url: "/client_v1/environment/get-auth-member",
		method: "POST",
		data,
	});
};
export const batchenvir = data => {
	//  duo环境多成员
	return request({
		url: "/client_v1/user/batch-auth-environment",
		method: "POST",
		data,
	});
};
export const batchaa = data => {
	//  单环境多成员
	return request({
		url: "/client_v1/environment/auth-member",
		method: "POST",
		data,
	});
};

export const getAuth = data => {
	//  获取已授权
	return request({
		url: "/client_v1/environment/get-auth-member",
		method: "POST",
		data,
	});
};

export const deleteTree = params => {
	return request({
		url: "/analysis/userGroup/tree/delete",
		method: "GET",
		params,
	});
};

export const environment_platform_list = data => {
	return request({
		url: "/client_v1/environment/platform-list",
		method: "POST",
		data,
	});
};

export const client_v1_device = data => {
	return request({
		url: "/client_v1/device/choose-device",
		method: "POST",
		data,
	});
};

export const getEnvironment_choose = data => {
	return request({
		url: "/client_v1/environment/environment-choose-data",
		method: "POST",
		data,
	});
};

export const environment_tag_list = data => {
	return request({
		url: "/client_v1/environment/environment-tag-list",
		method: "POST",
		data,
	});
};
export const environment_choose_list = data => {
	return request({
		url: "/client_v1/environment/choose-environment",
		method: "POST",
		data,
	});
};

export const environment_create = data => {
	return request({
		url: "/client_v1/environment/create",
		method: "POST",
		data,
	});
};

export const update_environment_tag = data => {
	return request({
		url: "/client_v1/environment/update-environment-tag",
		method: "POST",
		data,
	});
};

export const create_environment_tag = data => {
	return request({
		url: "/client_v1/environment/create-environment-tag",
		method: "POST",
		data,
	});
};

export const delete_environment_tag = data => {
	return request({
		url: "/client_v1/environment/delete-environment-tag",
		method: "POST",
		data,
	});
};

export const environment_bind_device = data => {
	return request({
		url: "/client_v1/environment/bind-device",
		method: "POST",
		data,
	});
};

export const environment_device_ip_list = data => {
	return request({
		url: "/client_v1/environment/device-ip-list",
		method: "POST",
		data,
	});
};

export const environment_device_name_list = data => {
	return request({
		url: "/client_v1/environment/device-name-list",
		method: "POST",
		data,
	});
};

export const environment_device_network_type_list = data => {
	return request({
		url: "/client_v1/environment/device-network-type-list",
		method: "POST",
		data,
	});
};

export const environment_business_short_list = data => {
	return request({
		url: "/client_v1/environment/business-short-list",
		method: "POST",
		data,
	});
};

export const environment_username_list = data => {
	return request({
		url: "/client_v1/environment/username-list",
		method: "POST",
		data,
	});
};

//环境详情
export const environment_info = data => {
	return request({
		url: "/client_v1/environment/info",
		method: "POST",
		data,
	});
};

// 批量授权环境 列表
export const choose_environment = data => {
	return request({
		url: "/client_v1/environment/choose-environment",
		method: "POST",
		data,
	});
};

//编辑环境
export const environment_update = data => {
	return request({
		url: "/client_v1/environment/update",
		method: "POST",
		data,
	});
};

//解绑设备
export const environment_unbind_device = data => {
	return request({
		url: "/client_v1/environment/unbind-device",
		method: "POST",
		data,
	});
};

//批量清空授权
export const environment_clear_auth_more = data => {
	return request({
		url: "/client_v1/environment/clear-auth-more",
		method: "POST",
		data,
	});
};

//批量清空环境标签
export const environment_clearenvironmenttagmore = data => {
	return request({
		url: "/client_v1/environment/clear-environment-tag-more",
		method: "POST",
		data,
	});
};

//批量批量解绑设备
export const environment_unbinddevicemore = data => {
	return request({
		url: "/client_v1/environment/unbind-device-more",
		method: "POST",
		data,
	});
};

//批量设为常用环境/取消常用环境
export const environment_commonmore = data => {
	return request({
		url: "/client_v1/environment/common-more",
		method: "POST",
		data,
	});
};

//批量删除环境标签
export const environment_deleteenvironmenttagmore = data => {
	return request({
		url: "/client_v1/environment/delete-environment-tag-more",
		method: "POST",
		data,
	});
};

// 打开环境获取数据
// export const environment_get_config = data => {
// 	return request({
// 		url: "/client_v1/environment/get-config",
// 		method: "POST",
// 		data,
// 	});
// };
export const environment_get_config = data => {
	return request({
		url: "/client_v1/environment/connect",
		method: "POST",
		data,
	});
};

//批量删除环境
export const environment_delete_more = data => {
	return request({
		url: "/client_v1/environment/delete-more",
		method: "POST",
		data,
	});
};

//购买设备列表
export const device_purchase_device_list = data => {
	return request({
		url: "/client_v1/device/purchase-device-list",
		// url: "/client_v1/device/init-device",
		method: "POST",
		data,
	});
};

//购买设备列表2
export const device_purchase_device_list2 = data => {
	return request({
		// url: "/client_v1/device/purchase-device-list",
		url: "/client_v1/device/init-device",
		method: "POST",
		data,
	});
};

// 设备详情
export const device_purchase_center = data => {
	return request({
		// url: "/client_v1/device/purchase-device-list",
		url: "/client_v1/device/device-product",
		method: "POST",
		data,
	});
};

// 购买时长列表
export const device_renew_device = data => {
	return request({
		url: "/client_v1/device/get-renew-device",
		method: "POST",
		data,
	});
};

// 续费
export const order_renewal_order = data => {
	return request({
		url: "/client_v1/order/renewal-order",
		method: "POST",
		data,
	});
};

// 续费
export const device_purchase_duration_list = data => {
	return request({
		url: "/client_v1/device/purchase-duration-list",
		method: "POST",
		data,
	});
};

// 购买时长列表
export const device_purchase_duration_product_info = data => {
	return request({
		url: "/client_v1/device/product-info",
		method: "POST",
		data,
	});
};

//购买设备下单接口
export const order_place = data => {
	return request({
		url: "/client_v1/order/place",
		method: "POST",
		data,
	});
};

// 获取useragent
export const getUserAgentApi = data => {
	return request({
		url: "/client_v1/environment/get-user-agent-v2",
		method: "POST",
		data,
	});
};

// 获取useragent
export const deviceSystemConfigApi = data => {
	return request({
		url: "/client_v1/environment/get-user-agent-config",
		method: "POST",
		data,
	});
};

// 批量获取环境信息
export const batchGetEnvInfoApi = data => {
	return request({
		url: "/client_v1/environment/connect-batch",
		method: "POST",
		data,
	});
};
