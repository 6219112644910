<template>
	<a-modal
		v-model="isshow"
		:width="925"
		title="绑定环境"
		@cancel="handleCancel"
		@ok="go_unbind">
		<div class="top_wrap">
			<div>{{ modaldata.created_at }}</div>
			<div>过期时间:{{ modaldata.expired_at }}</div>
			<div>
				已绑定 <i>{{ modaldata.env_name.length }}</i> 个平台
			</div>
		</div>

		<div class="tip">
			<i></i>
			<p>
				设备绑定账号前，请核对各平台政策，谨慎操作，每次设备绑定账号数不得超过20个
			</p>
		</div>

		<a-input-search
			placeholder="搜索环境"
			style="width: 200px; margin-bottom: 20px"
			@search="go_onSearch" />

		<a-table
			:row-selection="{
				selectedRowKeys: selectedRowKeys,
				onChange: onSelectChange,
			}"
			:loading="table_loading"
			:columns="base_columns"
			:data-source="table_data"
			:row-key="(r, i) => r.id"
			:scroll="{ y: 400 }"
			:pagination="pagination">
		</a-table>

		<div class="btn">
			<a-button @click="handleCancel"> 取消 </a-button>
			<a-button type="primary" @click="handleSuccess"> 确定 </a-button>
		</div>
	</a-modal>
</template>
<script>
import {
	device_bindnoenvironmentlist,
	device_bindenvironment,
	device_bind_device_more,
} from "@/api/equipment";
export default {
	props: {
		modalstatus: Boolean,
		modaldata: Object,
	},
	data() {
		return {
			isshow: false,
			selectedRowKeys: [], //表格 选中单元
			pagination: {
				pageNum: 1, //当前页数
				pageSize: 20, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
			table_data: [],
			base_columns: [],
			table_loading: false,
		};
	},
	mounted() {
		this.isshow = this.modalstatus;
		this.get_has_bindlist();
	},
	methods: {
		handleCancel() {
			this.isshow = false;
			this.$emit("cancel");
		},
		async handleSuccess() {
			if (!this.selectedRowKeys.length) {
				this.$message.error("请选择需要绑定的设备");
				return;
			}
			let { data } = await device_bind_device_more({
				id: this.selectedRowKeys.toString(),
				device_id: this.modaldata.id,
			});
			if (data.code === 200) {
				this.isshow = false;
				this.$message.success("绑定成功");
				this.$emit("cancel");
			} else {
				this.$message.error("绑定失败");
			}
		},
		go_onSearch(value) {
			this.get_has_bindlist(value);
		},
		//未绑定环境列表
		async get_has_bindlist(env_name = null) {
			this.table_loading = true;
			let { data } = await device_bindnoenvironmentlist({
				pagesize: 300,
				page: 1,
				env_name: env_name,
				device_id: this.modaldata.id,
			});
			this.table_loading = false;
			if (data.code == 200) {
				this.table_data = data.data.list;
				this.pagination.total = data.data.total;

				this.base_columns = [
					{
						title: "序号",
						dataIndex: "id",
					},
					{
						title: "环境名",
						dataIndex: "env_name",
					},

					{
						title: "所属平台",
						dataIndex: "site",
					},
					{
						title: "企业简称",
						dataIndex: "business_short",
					},
				];
			}
		},
		//表格行选中
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},
		async go_unbind() {
			let c_1 = [];
			this.selectedRowKeys.forEach(item => {
				c_1.push(this.table_data[item].id);
			});

			let { data } = await device_bindenvironment({
				environment_id: c_1.toString(),
				device_id: this.modaldata.id,
			});
			if (data.code == 200) {
				this.$message.success("绑定成功");
				this.isshow = false;
				this.$emit("success");
			}
		},

		//格式化状态
		formate_status(data) {
			if (data == 0) {
				return "正常";
			}
			if (data == 1) {
				return "过期";
			}
			if (data == 2) {
				return "待分配";
			}
			if (data == 3) {
				return "故障";
			}
			if (data == 4) {
				return "已删除";
			}
		},
	},
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-content {
	min-height: 300px;
}

/deep/ .ant-modal-header {
	border: 0;
}

/deep/ .ant-modal-footer {
	display: none;
}

/deep/ .ant-modal-body {
	padding-top: 0;
}

.top_wrap {
	display: flex;
	margin-bottom: 20px;

	div {
		display: flex;
		align-items: center;

		&::after {
			content: "";
			margin: 0 10px;
			width: 1px;
			height: 15px;
			background: #999999;
		}

		i {
			color: #4c84ff;
			font-style: normal;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}
}

.tip {
	width: 100%;
	height: 48px;
	background: #fff4e9;
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	i {
		background: url(../../../../assets/img/tips-icon.png) no-repeat center
			center;
		background-size: 20px 20px;
		width: 20px;
		height: 20px;
		display: inline-block;
		margin-right: 8px;
		margin-left: 20px;
	}

	p {
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		margin-bottom: 0;
	}
}

.btn {
	display: flex;
	justify-content: flex-end;

	.ant-btn {
		margin-left: 16px;
		border-radius: 0;
	}
}
</style>
