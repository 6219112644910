<template>
	<a-modal
		v-model="isshow"
		:width="660"
		title="编辑设备标签"
		@cancel="handleCancel"
		@ok="ok_handle">
		<div class="top_wrap">
			<div>{{ modaldata.created_at }}</div>
			<div>过期时间:{{ modaldata.expired_at }}</div>
			<div>
				已绑定 <i>{{ modaldata.env_name.length }}</i> 个平台
			</div>
		</div>

		<div class="cell_rightcont">
			<a-select
				mode="multiple"
				style="width: 380px"
				v-model="check_tagids"
				placeholder="请选择标签">
				<a-select-option
					v-for="item in data_list"
					:key="item.id"
					:value="item.id">
					{{ item.tag }}
				</a-select-option>
			</a-select>
		</div>
	</a-modal>
</template>
<script>
import { device_devicetaglist, device_devicebindtag } from "@/api/equipment";
export default {
	props: {
		modalstatus: Boolean,
		modaldata: Object,
	},
	data() {
		return {
			isshow: false,
			data_list: [],
			check_tagids: [],
		};
	},
	mounted() {
		this.isshow = this.modalstatus;
		// if (!this.modaldata.tags) {
		//   this.check_tagids = [];
		// } else {
		//   this.check_tagids = this.modaldata.tags.split(",");
		// }
		this.get_initdata();
	},
	methods: {
		handleCancel() {
			this.isshow = false;
			this.$emit("cancel");
		},
		async get_initdata() {
			let { data } = await device_devicetaglist({});
			if (data.code == 200) {
				this.check_tagids = [];
				this.data_list = data.data.list;
				//父组件传过来的数据是汉字，没有id，此处拿到id后存储
				this.data_list.forEach(item => {
					if (
						this.modaldata.tags.split(",").find(i => i == item.tag)
					) {
						this.check_tagids.push(item.id);
					}
				});
			}
		},
		async ok_handle() {
			if (this.check_tagids.length == 0) {
				this.$message.error("请选择标签");
				return;
			}
			let { data } = await device_devicebindtag({
				tags: this.check_tagids.toString(),
				device_id: this.modaldata.id,
			});
			if (data.code == 200) {
				this.$message.success("操作成功");
				this.isshow = false;
				this.$emit("success");
			}
		},
	},
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-content {
	// min-height: 300px;
}

/deep/ .ant-modal-header {
	border: 0;
}

.top_wrap {
	display: flex;
	margin-bottom: 34px;

	div {
		display: flex;
		align-items: center;

		&::after {
			content: "";
			margin: 0 10px;
			width: 1px;
			height: 15px;
			background: #999999;
		}

		i {
			color: #4c84ff;
			font-style: normal;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}
}
</style>
